<div id="kt_account_profile_details" class="card card-xl-stretch mb-xl-8 collapse show">
  <div
    class="card-header border-0 cursor-pointer"
    role="button"
    data-bs-toggle="collapse"
    data-bs-target="#kt_account_profile_details"
    aria-expanded="true"
    aria-controls="kt_account_profile_details">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">Edit market</h3>
    </div>
  </div>
  <form class="form" #marketForm="ngForm">
    <div class="card-body border-top p-9">
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Id</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                     [ngModel]="market.InstrumentID" placeholder="InstrumentID" name="InstrumentID" disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Enable</label>
        <div class="col-lg-8 fv-row">
          <input type="checkbox" class="form-check-input"
                 [(ngModel)]="market.enable">
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">SymbolFull</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                     [ngModel]="market.SymbolFull" placeholder="SymbolFull" name="SymbolFull" disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">InstrumentDisplayName</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                     [ngModel]="market.InstrumentDisplayName" placeholder="InstrumentDisplayName" name="InstrumentDisplayName" disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">TypeId</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                     [ngModel]="market.TypeId" placeholder="TypeId" name="TypeId" disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">PriceSource</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                     [ngModel]="market.PriceSource" placeholder="PriceSource" name="PriceSource" disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button type="button" class="btn btn-success" (click)="save()">Save</button>
      <button type="button" class="btn btn-primary" (click)="back()">Back</button>
    </div>
  </form>
</div>
