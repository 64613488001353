import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../../../services/product.service';
import {Product} from '../../../../model/paypal/product';

@Component({
  selector: 'app-product-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class ProductEditComponent implements OnInit {

  currencyes = [
    {id: 1, name: 'USD'},
    {id: 2, name: 'EUR'},
  ];

  product: Product;
  productId: number;
  mode: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private productService: ProductService) {
    this.product = new Product();
  }

  ngOnInit(): void {
    this.productId = this.route.snapshot.queryParams['productId'];
    this.mode = this.route.snapshot.queryParams['mode'];

    if (this.productId != null && this.mode === 'edit') {
      this.productService.findById(this.productId)
        .toPromise()
        .then((data) => {
          if (data != null) {
            this.product = data;
            this.changeDetector.detectChanges();
          }
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    } else {
      this.product = new Product();
    }
  }

  save(): void {
    this.productService.save(this.product)
      .toPromise()
      .then((result) => {
        this.router.navigate(['/productList'],
          {
            queryParams:
              {
                messageData: result.answer
              }
          });
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  back(): void {
    this.router.navigate(['/productList']);
  }
}
