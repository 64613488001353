export class PageMarketRequest {
  page: number;
  size: number;

  ascDirection: number;
  criteria: string;

  instrumentID: any;
  symbolFull: any;
  searchEnable: any;
  instrumentDisplayName: any;
  typeId: string;
  priceSource: any;

  constructor(page: number, size: number, ascDirection: number) {
    this.page = page;
    this.size = size;
    this.ascDirection = ascDirection;
  }
}
