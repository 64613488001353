import {Injectable} from '@angular/core';
import {Parser} from '../model/parser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

const API_USERS_URL = `${environment.apiUrl}/parser`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ParserService {

  constructor(private http: HttpClient) {
  }

  findAllParsers(): Observable<Parser[]> {
    return this.http.get<Parser[]>(`${API_USERS_URL}`);
  }
}
