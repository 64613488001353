import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserModel} from '../../models/user.model';
import {ResponseModel} from '../../../../model/response-model';
import {environment} from 'src/environments/environment';

const API_USERS_URL = `${environment.apiUrl}/auth`;
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {
  }

  // public methods
  authenticate(email: string, password: string): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/authenticate`, {
      email,
      password,
    }, httpOptions);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/register`, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<ResponseModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<ResponseModel>(`${API_USERS_URL}/foundMe`, {
      headers: httpHeaders,
    });
  }

  registerUser(user: UserModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/register`, user, httpOptions);
  }
}
