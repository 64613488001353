<div id="kt_account_profile_details" class="card card-xl-stretch mb-xl-8 collapse show">
  <div
    class="card-header border-0 cursor-pointer"
    role="button"
    data-bs-toggle="collapse"
    data-bs-target="#kt_account_profile_details"
    aria-expanded="true"
    aria-controls="kt_account_profile_details">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">
        {{title}}
      </h3>
    </div>
  </div>

  <form novalidate="" class="form" #userForm="ngForm">
    <div class="card-body border-top p-9">
      <div class="row mb-4">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Id</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                     [ngModel]="user.id" placeholder="Id" name="Id" class="form-control" disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">User name</label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                 [(ngModel)]="user.username" placeholder="User name" name="User name"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Full name</span>
        </label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                     [(ngModel)]="user.firstname" placeholder="First name" name="First name"
              />
            </div>
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solid"
                     [(ngModel)]="user.lastname" placeholder="Last name" name="Last name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Email</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="user.email" placeholder="Email" name="Email"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Role</span>
        </label>
        <div class="col-lg-8 fv-row">
          <select class="form-control" [(ngModel)]="user.role" id="role" name="formRole">
            <option disabled>Select. Current: {{ user.role}}</option>
            <option *ngFor="let role of roles" [ngValue]="role.name">{{ role.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>Phone</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="user.phone" placeholder="Phone" name="Phone"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>Address</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="user.address" placeholder="Address" name="Address"
          />
        </div>
      </div>

      <div class="row mb-6" *ngIf="canCreatePassword">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Password</span>
        </label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="password" class="form-control form-control-lg form-control-solid"
                     [(ngModel)]="password" placeholder="Password" name="Password"
              />
            </div>
            <div class="col-lg-6 fv-row">
              <button class="btn btn-success" (click)="changePassword()" *ngIf="canChangePassword">Change password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button type="button" class="btn btn-success mx-2" (click)="save()">Save</button>
      <button type="button" class="btn btn-primary" (click)="back()">Back</button>
    </div>
  </form>
</div>
