import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Market} from '../model/market';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ResponseModel} from '../model/response-model';
import {PageResponse} from "../model/paging/pageResponse";
import {PageMarketRequest} from "../model/paging/pageMarketRequest";

const API_USERS_URL = `${environment.apiUrl}/market`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class MarketService {

  constructor(private http: HttpClient) {
  }

  findByTrader(traderid: string, pageRequest: PageMarketRequest): Observable<PageResponse> {
    return this.http.post<PageResponse>(`${API_USERS_URL}/bytrader/` + traderid, pageRequest, httpOptions);
  }

  findAllMarket(pageRequest: PageMarketRequest): Observable<PageResponse> {
    return this.http.post<PageResponse>(`${API_USERS_URL}`, pageRequest, httpOptions);
  }

  findById(id: string): Observable<Market> {
    return this.http.get<Market>(`${API_USERS_URL}/id/` + id, httpOptions);
  }

  save(market: Market): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/save`, market, httpOptions);
  }

  delete(id: number): Observable<ResponseModel> {
    return this.http.delete<ResponseModel>(`${API_USERS_URL}/` + id, httpOptions);
  }
}
