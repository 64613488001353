import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MarketService} from '../../../services/market.service';
import {Market} from '../../../model/market';

@Component({
  selector: 'app-market-edit',
  templateUrl: './market-edit.component.html',
  styleUrls: ['./market-edit.component.scss']
})
export class MarketEditComponent implements OnInit {

  private unsubscribe: Subscription[] = [];

  market: Market;
  id: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private marketService: MarketService) {
    this.market = new Market();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.queryParams.id;
    if (this.id != null) {
      this.marketService.findById(this.id)
        .toPromise()
        .then((data) => {
          if (data != null) {
            this.market = data;
            this.changeDetector.detectChanges();
          }
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    }
  }

  save(): void {
    this.marketService.save(this.market)
      .toPromise()
      .then((result) => {
        this.router.navigate(['/marketlist'],
          {
            queryParams:
              {
                messageData: result.answer
              }
          });
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  back(): void {
    this.router.navigate(['/marketlist']);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
