<div class="card mb-10 gx-5">

  <div [hidden]="!alert" class="alert alert-primary" role="alert">
    {{alertMessage}}
  </div>

  <div id="kt_account_profile_details" class="card card-xl-stretch mb-xl-1 collapse show">
    <div class="card-body p-3">
      <div class="row mb-3">
        <div class="col-sm-10">
          <div class="row">
            <div class="col-sm-3 fv-row input-group-sm mb-3">
              <span class="input-group-text">From:</span>
            </div>
            <div class="col-sm-3 fv-row">
              <input type="date" [(ngModel)]="dateFrom" (change)="updateDeals()" placeholder="date from"
                     class="form-control form-control-sm" id="dateFrom">
            </div>
            <div class="col-sm-3 fv-row input-group-sm mb-3">
              <span class="input-group-text">To:</span>
            </div>
            <div class="col-sm-3 fv-row">
              <input type="date" [(ngModel)]="dateTo" (change)="updateDeals()" placeholder="date to"
                     class="form-control form-control-sm" id="dateTo">
            </div>
          </div>
          <!--          <br>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-3 fv-row input-group-sm mb-3">-->
          <!--              <span class="input-group-text">Update enable:</span>-->
          <!--            </div>-->
          <!--            <div class="col-sm-3 fv-row">-->
          <!--              <input type="checkbox" [(ngModel)]="updateEnable" (change)="onCheckboxChange(secondsCount)"-->
          <!--                     placeholder="Update enable"-->
          <!--                     name="Update enable"-->
          <!--                     class="form-check-input" id="updateEnable">-->
          <!--            </div>-->
          <!--            <div class="col-sm-3 fv-row input-group-sm mb-3">-->
          <!--              <span class="input-group-text">Update every(sec):</span>-->
          <!--            </div>-->
          <!--            <div class="col-sm-3 fv-row">-->
          <!--              <input type="number" [(ngModel)]="secondsCount" (input)="updateLoadingPeriod()" placeholder="Seconds"-->
          <!--                     name="Seconds"-->
          <!--                     class="form-control form-control-sm" id="seconds">-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <br>-->
        </div>
      </div>
    </div>
  </div>

  <div class="row py-1">
    <div class="col">
      <div class="card-body py-1">
              <span class="text-lg-start">
                NEW Deals
              </span>
        <div class="table-responsive">
          <table datatable class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
            <thead>
            <tr class="fw-bolder text-muted">
              <th (click)="sortBy('index')">Index</th>
              <th (click)="sortBy('buySell')">BuySell</th>
              <th (click)="sortBy('status')">Status</th>
              <th class="min-w-20px" align="left"></th>
              <th (click)="sortBy('market.SymbolFull')">Market</th>
              <th (click)="sortBy('priceMin')">Min ></th>
              <th (click)="sortBy('priceMax')">Max ></th>
              <th (click)="sortBy('rating')">Rating ></th>
              <th scope="col"></th>
            </tr>
            <tr>
              <td>
                <input type="text" class="form-control ng-untouched ng-pristine ng-valid" placeholder="Index">
              </td>
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="BuySell"
                       [(ngModel)]="searchByBuySell"
                       (input)="changeFilterValue('buySell', searchByBuySell)">
              </td>
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="Status"
                       [(ngModel)]="searchByStatus"
                       (input)="changeFilterValue('status', searchByStatus)">
              </td>
              <td>
              </td>
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="Market"
                       [(ngModel)]="searchSymbolFull"
                       (input)="changeFilterValue('market.SymbolFull', searchSymbolFull)">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="min"
                       [(ngModel)]="searchSymbolPriceMin"
                       (input)="changeFilterValue('priceMin', searchSymbolPriceMin)">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="max"
                       [(ngModel)]="searchSymbolPriceMax"
                       (input)="changeFilterValue('priceMax', searchSymbolPriceMax)">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="rating"
                       [(ngModel)]="searchRating"
                       (input)="changeFilterValue('rating', searchRating)">
              </td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let subMarket of subMarkets   |
                  paginate: {  id: 'paginationList1',
                                   itemsPerPage: pageSize1,
                                   currentPage: page1,
                                   totalItems: count1 } ;
                  let i = index"
                [ngClass]="{'buyingOpened': subMarket.buySell == 'BUY' && subMarket.status == 'NEW',
                             'sellingOpened': subMarket.buySell == 'SELL' && subMarket.status == 'NEW'}">
              <td class="tableId">{{(pageSize1 * (page1 - 1)) + i + 1}}</td>
              <td
                [ngClass]="{'sellingRed': subMarket.buySell == 'SELL' && subMarket.status == 'NEW'}">{{subMarket.buySell}}
              </td>
              <td>{{subMarket.status}}</td>
              <td>
                <ng-container *ngIf="subMarket.market.Photo !== ''">
                  <a href="https://www.etoro.com/markets/{{subMarket.market.SymbolFull}}" target="_blank"
                     class="fs-5 text-dark text-hover-primary fw-bolder">
                    <img alt="Logo" src="{{subMarket.market.Photo}}" class="h-45px"/>
                  </a>
                </ng-container>
              </td>
              <td>{{subMarket.market.SymbolFull}}</td>
              <td>{{subMarket.priceMin}}</td>
              <td>{{subMarket.priceMax}}</td>
              <td>{{subMarket.rating}}</td>
              <td class="tableButton">
                <div class="d-flex justify-content-end flex-shrink-0">
                  <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                          (click)="doSubscribe(subMarket)" *ngIf="subMarket.userCount===0">
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/arrows/arr013.svg'"
                  class="svg-icon svg-icon-2"></span>
                  </button>
                  <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                          (click)="doUnsubscribe(subMarket)" *ngIf="subMarket.userCount!=0">
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/arrows/arr014.svg'"
                  class="svg-icon svg-icon-2"></span>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="pagination1">
        <pagination-controls
          id="paginationList1"
          previousLabel="Prev"
          nextLabel="Next"
          responsive="true"
          (pageChange)="handlePageChange1($event)"
        ></pagination-controls>
      </div>
    </div>
    <div class="col">
      <div class="card-body py-1">
              <span class="text-lg-start">
                CLOSED Deals
              </span>
        <div class="table-responsive">
          <table datatable class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
            <thead>
            <tr class="fw-bolder text-muted">
              <th (click)="sortBy('index')">Index</th>
              <th (click)="sortBy('buySell')">BuySell</th>
              <th (click)="sortBy('status')">Status</th>
              <th class="min-w-20px" align="left"></th>
              <th (click)="sortBy('market.SymbolFull')">Market</th>
              <th (click)="sortBy('priceMin')">Min ></th>
              <th (click)="sortBy('priceMax')">Max ></th>
              <th (click)="sortBy('rating')">Rating ></th>
              <th scope="col"></th>
            </tr>
            <tr>
              <td>
                <input type="text" class="form-control ng-untouched ng-pristine ng-valid" placeholder="Index">
              </td>
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="BuySell"
                       [(ngModel)]="searchByBuySell"
                       (input)="changeFilterValue('buySell', searchByBuySell)">
              </td>
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="Status"
                       [(ngModel)]="searchByStatus"
                       (input)="changeFilterValue('status', searchByStatus)">
              </td>
              <td>
              </td>
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="Market"
                       [(ngModel)]="searchSymbolFull"
                       (input)="changeFilterValue('market.SymbolFull', searchSymbolFull)">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="min"
                       [(ngModel)]="searchSymbolPriceMin"
                       (input)="changeFilterValue('priceMin', searchSymbolPriceMin)">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="max"
                       [(ngModel)]="searchSymbolPriceMax"
                       (input)="changeFilterValue('priceMax', searchSymbolPriceMax)">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid"
                       placeholder="rating"
                       [(ngModel)]="searchRating"
                       (input)="changeFilterValue('rating', searchRating)">
              </td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let subMarket2 of subMarkets2   |
                  paginate: { id: 'paginationList2',
                                   itemsPerPage: pageSize2,
                                   currentPage: page2,
                                   totalItems: count2 } ;
                  let i = index"
                [ngClass]="{'buyingOpened': subMarket2.buySell == 'BUY' && subMarket2.status == 'NEW',
                             'sellingOpened': subMarket2.buySell == 'SELL' && subMarket2.status == 'NEW'}">
              <td class="tableId">{{(pageSize2 * (page2 - 1)) + i + 1}}</td>
              <td
                [ngClass]="{'sellingRed': subMarket2.buySell == 'SELL' && subMarket2.status == 'NEW'}">{{subMarket2.buySell}}
              </td>
              <td>{{subMarket2.status}}</td>
              <td>
                <ng-container *ngIf="subMarket2.market.Photo !== ''">
                  <a href="https://www.etoro.com/markets/{{subMarket2.market.SymbolFull}}" target="_blank"
                     class="fs-5 text-dark text-hover-primary fw-bolder">
                    <img alt="Logo" src="{{subMarket2.market.Photo}}" class="h-45px"/>
                  </a>
                </ng-container>
              </td>
              <td>{{subMarket2.market.SymbolFull}}</td>
              <td>{{subMarket2.priceMin}}</td>
              <td>{{subMarket2.priceMax}}</td>
              <td>{{subMarket2.rating}}</td>
              <td class="tableButton">
                <div class="d-flex justify-content-end flex-shrink-0">
                  <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                          (click)="doSubscribe(subMarket2)" *ngIf="subMarket2.userCount===0">
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/arrows/arr013.svg'"
                  class="svg-icon svg-icon-2"></span>
                  </button>
                  <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                          (click)="doUnsubscribe(subMarket2)" *ngIf="subMarket2.userCount!=0">
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/arrows/arr014.svg'"
                  class="svg-icon svg-icon-2"></span>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="pagination2">
        <pagination-controls
          id="paginationList2"
          previousLabel="Prev"
          nextLabel="Next"
          responsive="true"
          (pageChange)="handlePageChange2($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>

<div hidden>
  <button id="kt_engage_demos_toggle"
          class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0"
          #premium
          title="" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-dismiss="click"
          data-bs-trigger="hover"
          data-bs-original-title="Check out 20 more demos">
    <span id="kt_engage_demos_label">PremiumPopupComponent</span>
  </button>
</div>

