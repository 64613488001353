<div id="kt_account_profile_details" class="card card-xl-stretch mb-xl-8 collapse show">
  <div
    class="card-header border-0 cursor-pointer"
    role="button"
    data-bs-toggle="collapse"
    data-bs-target="#kt_account_profile_details"
    aria-expanded="true"
    aria-controls="kt_account_profile_details">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">Edit product</h3>
    </div>
  </div>
  <form class="form" #productForm="ngForm">
    <div class="card-body border-top p-9">
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Id</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                     [ngModel]="product.id" placeholder="id" name="id" disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Active</label>
        <div class="col-lg-8 fv-row">
          <input type="checkbox" class="form-check-input"
                 [(ngModel)]="product.active">
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Name</label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                 [(ngModel)]="product.name" placeholder="Name" name="Name"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Month</label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                 [(ngModel)]="product.month" placeholder="Month" name="Month"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Subtotal</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="product.subTotal" placeholder="Subtotal" name="Subtotal"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Tax</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="product.tax" placeholder="Tax" name="Tax"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Currency</span>
        </label>
        <div class="col-lg-8 fv-row">
          <select class="form-control" [(ngModel)]="product.currency" id="role" name="formRole">
            <option disabled>Select. Current: {{ product.currency}}</option>
            <option *ngFor="let currency of currencyes" [ngValue]="currency.name">{{ currency.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Stripe price id</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" disabled class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="product.stripePriceId" placeholder="Stripe price id" name="stripePriceId"
          />
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button type="button" class="btn btn-success" (click)="save()">Save</button>
      <button type="button" class="btn btn-primary" (click)="back()">Back</button>
    </div>
  </form>
</div>
