import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Deal} from '../../model/deal';
import {DealService} from '../../services/deal.service';
import {environment} from '../../../environments/environment';
import {ResponseModel} from '../../model/response-model';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../modules/auth';

@Component({
  selector: 'app-deals-consolidate',
  templateUrl: './deals-consolidate.component.html',
  styleUrls: ['./deals-consolidate.component.scss']
})
export class DealsConsolidateComponent implements OnInit {


  @ViewChild('premium') premium: ElementRef;

  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private ascDirection = 1;

  responseModel: ResponseModel;

  title: string;
  subMarkets: Deal[];
  subMarkets2: Deal[];

  subMarketsY: Deal[]; // yesterday
  subMarkets2Y: Deal[];

  userEmail: string;

  page1 = 1;
  count1 = 0;
  pageSize1 = 10;

  page2 = 1;
  count2 = 0;
  pageSize2 = 10;

  sortByBuySell: boolean;
  sortByStatus: boolean;
  sortBySymbolFull: boolean;
  sortByRating: boolean;
  sortBySymbolPriceMin: boolean;
  sortBySymbolPriceMax: boolean;

  searchByBuySell: any;
  searchByStatus: any;
  searchSymbolFull: any;
  searchRating: number;
  searchSymbolPriceMin: number;
  searchSymbolPriceMax: number;

  secondsCount: number;
  updateEnable: boolean;
  dayForFilter: number;
  dateFrom: string;
  dateTo: string;
  priceMin: number;
  priceMax: number;
  rating: number;

  criteria: string;

  columnFilter: string;
  valueFilter: string;

  alert = false;
  alertMessage: string;
  timerId: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private subMarketService: DealService,
              private authService: AuthService,
              private changeDetector: ChangeDetectorRef) {
    this.title = 'Real time deals';
  }

  ngOnInit(): void {
    const user = this.authService.currentUserValue;
    if (user) {
      if (user.createdAt && user.role === 'ROLE_USER' && !user.premium) {
        const createdAt = new Date(user.createdAt);
        const delta = (new Date().valueOf() - createdAt.valueOf()) / (60 * 60 * 24 * 1000);
        if (delta > 3) {
          const warn = 'Trial of 3 days has ended!';

          this.router.navigate(['/payment'],
            {
              queryParams:
                {
                  marker: warn
                }
            });
        }
      }
    }

    this.secondsCount = 30;
    this.updateEnable = true;
    this.dayForFilter = 1;

    this.dateFrom = new Date().toISOString().slice(0, 10);
    this.dateTo = new Date().toISOString().slice(0, 10);

    if (this.route.snapshot.queryParams.messageData != null) {
      this.viewMessage(this.route.snapshot.queryParams.messageData);
    }

    console.log('From: ' + this.dateFrom + ' - ' + ' To: ' + this.dateTo);

    this.authService.getUserByToken()
      .toPromise()
      .then(user => {
        if (user) {
          this.userEmail = user.email;
          this.reloadDataByUser();
          this.timerId = setInterval(() => this.reloadDataByUser(), this.secondsCount * 1000);
        }
      });
  }

  private viewMessage(message: string): void {
    this.alert = true;
    this.alertMessage = message;
    setTimeout(() => {
      this.alert = false;
      this.changeDetector.detectChanges();
    }, 3000);
  }


  reloadDataByUser(): void {
    if (this.userEmail != null) {
      this.subMarketService.findByUserIdAndDayFilter(this.userEmail, new Date(this.dateFrom).toISOString(), new Date(new Date(this.dateTo).setUTCHours(23, 59, 59, 999)).toISOString())
        .toPromise()
        .then(response => {
          let temp: Deal[] = [];
          if (response && response.httpStatus !== 'NOT_FOUND') {
            temp = JSON.parse(response.model.today);
            temp = this.sorting(temp);
            temp = this.filter(temp);

            console.log('Update reload every ' + this.secondsCount);

            this.subMarkets = this.filterBy(temp, 'NEW');
            this.subMarkets2 = this.filterBy(temp, 'CLOSE');

            this.count1 = this.subMarkets.length;
            this.count2 = this.subMarkets2.length;
            this.changeDetector.detectChanges();
          } else {
            this.count1 = 0;
            this.count2 = 0;
            this.viewMessage('No data');
          }
        }, (error) => {
          localStorage.removeItem(this.authLocalStorageToken);
          window.location.href = '/auth/login';
        });
    }
  }

  handlePageChange1(event: number): void {
    this.page1 = event;
  }

  handlePageChange2(event: number): void {
    this.page2 = event;
  }

  sortBy(criteria: string): void {
    if (this.ascDirection === 1) {
      this.ascDirection = -1;
    } else {
      this.ascDirection = 1;
    }

    switch (criteria) {
      case 'buySell':
        this.sortByBuySell = true;
        break;
      case 'status':
        this.sortByStatus = true;
        break;
      case 'market.SymbolFull':
        this.sortBySymbolFull = true;
        break;
      case 'priceMin':
        this.sortBySymbolPriceMin = true;
        break;
      case 'priceMax':
        this.sortBySymbolPriceMax = true;
        break;
      case 'rating':
        this.sortByRating = true;
        break;
      default:
        break;

    }
    this.criteria = criteria;
    this.reloadDataByUser();
  }

  changeFilterValue(column: string, value: any): void {
    this.columnFilter = column;
    this.valueFilter = value;
    this.reloadDataByUser();
  }

  doSubscribe(subMarket: Deal): void {
    this.subMarketService.doSubscribe(subMarket.id, this.userEmail)
      .toPromise()
      .then(data => {
          if (data && data.httpStatus === 'OK') {
            this.viewMessage(data.answer);
            this.reloadDataByUser();
          } else if (data && data.httpStatus === 'CREATED') {
            this.viewMessage('No access without premium');
            this.premium.nativeElement.click();
          }
          console.log(data.answer);
        }, error => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        }
      );
  }

  doUnsubscribe(subMarket: Deal): void {
    this.subMarketService.doUnsubscribe(subMarket.id, this.userEmail)
      .toPromise()
      .then(data => {
          this.viewMessage(data.answer);
          this.reloadDataByUser();
        }, error => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        }
      );
  }

  onCheckboxChange(secondsCount: number): void {
    if (this.updateEnable) {
      this.timerId = setInterval(() => this.reloadDataByUser(), this.secondsCount * 1000);
    } else {
      clearInterval(this.timerId);
    }
    console.log('change checkbox timerId: ' + this.timerId);
  }

  updateLoadingPeriod(): void {
    if (this.updateEnable) {
      if (this.secondsCount < 5) {
        this.viewMessage('Minimal value for timer is 5');
        this.secondsCount = 5;
      }
      clearInterval(this.timerId);
      this.timerId = setInterval(() => this.reloadDataByUser(), this.secondsCount * 1000);
    }
  }

  createSubmarkets(): void {
    this.subMarketService.createAll().subscribe(value => {
        this.responseModel = value;
      }, error => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      }
    );
  }

  updateDeals(): void {
    this.reloadDataByUser();
  }

  private sorting(subMarkets: Deal[]): Deal[] {
    if (this.ascDirection === -1) {
      if (this.sortByBuySell) {
        subMarkets.sort((a, b) => a.buySell.localeCompare(b.buySell));
      }
      if (this.sortByStatus) {
        subMarkets.sort((a, b) => a.status.localeCompare(b.status));
      }
      if (this.sortBySymbolFull) {
        subMarkets.sort((a, b) => a.market.SymbolFull.localeCompare(b.market.SymbolFull));
      }
      if (this.sortBySymbolPriceMin) {
        subMarkets.sort((a, b) =>
          a.priceMin > b.priceMin ? 1 : -1);
      }
      if (this.sortBySymbolPriceMax) {
        subMarkets.sort((a, b) => a.priceMax > b.priceMax ? 1 : -1);
      }
      if (this.sortByRating) {
        subMarkets.sort((a, b) => (a.rating != null ? a.rating : Infinity) - (b.rating != null ? b.rating : Infinity));
      }
    } else {
      if (this.sortByBuySell) {
        subMarkets.sort((a, b) => b.buySell.localeCompare(a.buySell));
      }
      if (this.sortByStatus) {
        subMarkets.sort((a, b) => b.status.localeCompare(a.status));
      }
      if (this.sortBySymbolFull) {
        subMarkets.sort((a, b) => b.market.SymbolFull.localeCompare(a.market.SymbolFull));
      }
      if (this.sortBySymbolPriceMin) {
        subMarkets.sort((a, b) => b.priceMin > a.priceMin ? 1 : -1);
      }
      if (this.sortBySymbolPriceMax) {
        subMarkets.sort((a, b) => b.priceMax > a.priceMax ? 1 : -1);
      }
      if (this.sortByRating) {
        subMarkets.sort((a, b) => (b.rating != null ? b.rating : -Infinity) - (a.rating != null ? a.rating : -Infinity));
      }
    }

    return subMarkets;
  }

  private filter(subMarkets: Deal[]): Deal[] {

    if (this.columnFilter !== undefined && this.valueFilter !== undefined) {
      if (this.searchByBuySell != null) {
        subMarkets = subMarkets.filter(item => item.buySell.toLowerCase().includes(this.searchByBuySell.toLowerCase()));
      }
      if (this.searchByStatus != null) {
        subMarkets = subMarkets.filter(item => item.status.toLowerCase().includes(this.searchByStatus.toLowerCase()));
      }
      if (this.searchSymbolFull != null) {
        subMarkets = subMarkets.filter(item => item.market.SymbolFull.toLowerCase().includes(this.searchSymbolFull.toLowerCase()));
      }
      if (this.searchSymbolPriceMin != null) {
        subMarkets = subMarkets.filter(item => item.priceMin > this.searchSymbolPriceMin);
      }
      if (this.searchSymbolPriceMax != null) {
        subMarkets = subMarkets.filter(item => item.priceMax > this.searchSymbolPriceMax);
      }
      if (this.searchRating != null) {
        subMarkets = subMarkets.filter(item => item.rating > this.searchRating);
      }
    }

    return subMarkets;
  }

  private filterBy(subMarkets: Deal[], value: string): Deal[] {
    if (value === 'NEW') {
      subMarkets = subMarkets.filter(item => item.status.includes('NEW'));
    }
    if (value === 'CLOSE') {
      subMarkets = subMarkets.filter(item => item.status.includes('CLOSE'));
    }

    return subMarkets;
  }

  onCheckboxSubscribedChange(): void {
    this.reloadDataByUser();
  }
}
