<div class="card mb-5 mb-xl-8">

  <div class="card-body py-3">
    <div class="table-responsive">
      <table datatable class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
        <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px">Index</th>
          <th class="min-w-80px" (click)="sortBy('id')">Id</th>
          <th class="min-w-200px" (click)="sortBy('name')">Name</th>
          <th class="min-w-50px" (click)="sortBy('parser')">Parser</th>
          <th class="min-w-50px" (click)="sortBy('rating')">Rating</th>
          <th class="min-w-100px text-end" scope="col" colspan="4">Actions</th>
        </tr>
        <tr>
          <td>
            <input type="text" class="form-control ng-untouched ng-pristine ng-valid" placeholder="Index">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Id"
                   [(ngModel)]="searchId"
                   (input)="changeFilterValue('id', searchId)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Name"
                   [(ngModel)]="searchName"
                   (input)="changeFilterValue('name', searchName)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Parser"
                   [(ngModel)]="searchParser"
                   (input)="changeFilterValue('parser.id', searchParser)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Rating"
                   [(ngModel)]="searchRating"
                   (input)="changeFilterValue('rating', searchRating)">
          </td>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let trader of traders  |
                  paginate: { itemsPerPage: pageSize,
                                   currentPage: pagePaging,
                                   totalItems: count } ;
                  let i = index">
          <td>{{(pageSize * (pagePaging - 1)) + i + 1}}</td>
          <td>{{trader.id}}</td>
          <td>{{trader.name}}</td>
          <td>{{trader.parser?.id}}</td>
          <td>{{trader.rating}}</td>
          <td class="tableButton">
            <div class="d-flex justify-content-end flex-shrink-0">
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="edit(trader.id)"
                      title="Edit the trader">
              <span
                [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                class="svg-icon svg-icon-2"></span>
              </button>
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="delete(trader.id)"
                      title="Delete the trader">
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                class="svg-icon svg-icon-2">
              </span>
              </button>
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="marketsByTrader(trader)"
                      title="Open a markets by the trader">M
              </button>
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="quotesByTrader(trader)"
                      title="Open a quotes by the trader">Q
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="pagination">
        <pagination-controls
          previousLabel="Prev"
          nextLabel="Next"
          responsive="true"
          (pageChange)="handlePageChange($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>

<div [hidden]="!alert" class="alert alert-primary" role="alert">
  {{alertMessage}}
</div>
