<div class="card mb-10 gx-5">

  <div [hidden]="!alert" class="alert alert-primary" role="alert">
    {{alertMessage}}
  </div>

  <div id="kt_account_profile_details" class="card card-xl-stretch mb-xl-1 collapse show">
    <div class="card-body p-3">
      <div class="row mb-3">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-3 fv-row input-group-sm mb-3">
              <span class="input-group-text">From:</span>
            </div>
            <div class="col-sm-3 fv-row">
              <input type="date" [(ngModel)]="dateFrom" (change)="updateDeals()" placeholder="date from"
                     class="form-control form-control-sm" id="dateFrom">
            </div>
            <div class="col-sm-3 fv-row input-group-sm mb-3">
              <span class="input-group-text">To:</span>
            </div>
            <div class="col-sm-3 fv-row">
              <input type="date" [(ngModel)]="dateTo" (change)="updateDeals()" placeholder="date to"
                     class="form-control form-control-sm" id="dateTo">
            </div>
          </div>
          <!--          <br>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-3 fv-row input-group-sm mb-3">-->
          <!--              <span class="input-group-text">Update enable:</span>-->
          <!--            </div>-->
          <!--            <div class="col-sm-3 fv-row">-->
          <!--              <input type="checkbox" [(ngModel)]="updateEnable" (change)="onCheckboxChange(secondsCount)"-->
          <!--                     placeholder="Update enable"-->
          <!--                     name="Update enable"-->
          <!--                     class="form-check-input" id="updateEnable">-->
          <!--            </div>-->
          <!--            <div class="col-sm-3 fv-row input-group-sm mb-3">-->
          <!--              <span class="input-group-text">Update every(sec):</span>-->
          <!--            </div>-->
          <!--            <div class="col-sm-3 fv-row">-->
          <!--              <input type="number" [(ngModel)]="secondsCount" (input)="updateLoadingPeriod()" placeholder="Seconds"-->
          <!--                     name="Seconds"-->
          <!--                     class="form-control form-control-sm" id="seconds">-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <br>-->
        </div>
      </div>
    </div>
  </div>

  <div class="row py-1">
    <div class="col">
      <div class="card-body py-1">
        <h3>
          Today new deals
        </h3>
        <div class="table">
          <table datatable class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
            <thead>
<!--            <tr class="fw-bolder text-muted">-->
<!--&lt;!&ndash;              <th class="text-center" style="width:7%" (click)="sortBy('index')">Index</th>&ndash;&gt;-->
<!--              <th class="text-center" style="width:8%" (click)="sortBy('buySell')">BuySell</th>-->
<!--              <th class="text-center" style="width:7%" (click)="sortBy('status')">Status</th>-->
<!--              <th class="text-center" style="width:5%">Icon</th>-->
<!--              <th class="text-center" style="width:8%" (click)="sortBy('market.SymbolFull')">Market</th>-->
<!--              <th class="text-center" style="width:7%" (click)="sortBy('priceMin')">Min></th>-->
<!--              <th class="text-center" style="width:7%" (click)="sortBy('priceMax')">Max></th>-->
<!--              <th class="text-center" style="width:8%" (click)="sortBy('rating')">Rating></th>-->
<!--              <th class="text-center">News (links)</th>-->
<!--              <th scope="text-center" style="width:8%">Subscribe</th>-->
<!--            </tr>-->
            <tr>
<!--              <td>-->
<!--                <input type="text" class="form-control ng-untouched ng-pristine ng-valid paddingU"-->
<!--                       placeholder="Index">-->
<!--              </td>-->
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid  paddingU"
                       placeholder="BuySell"
                       [(ngModel)]="searchByBuySell"
                       (input)="changeFilterValue('buySell', searchByBuySell)" style="width:5em;">
              </td>
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid paddingU "
                       placeholder="Status"
                       [(ngModel)]="searchByStatus"
                       (input)="changeFilterValue('status', searchByStatus)" style="width:5em;">
              </td>
              <td></td>
              <td>
                <input type="text"
                       class="form-control ng-untouched ng-pristine ng-valid  paddingU"
                       placeholder="Market"
                       [(ngModel)]="searchSymbolFull"
                       (input)="changeFilterValue('market.SymbolFull', searchSymbolFull)" style="width:5em;">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid paddingU "
                       placeholder="min"
                       [(ngModel)]="searchSymbolPriceMin"
                       (input)="changeFilterValue('priceMin', searchSymbolPriceMin)" style="width:5em;">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid  paddingU"
                       placeholder="max"
                       [(ngModel)]="searchSymbolPriceMax"
                       (input)="changeFilterValue('priceMax', searchSymbolPriceMax)" style="width:5em;">
              </td>
              <td>
                <input type="number"
                       class="form-control ng-untouched ng-pristine ng-valid paddingU "
                       placeholder="rate"
                       [(ngModel)]="searchRating"
                       (input)="changeFilterValue('rating', searchRating)" style="width:5em;">
              </td>
              <td>
              <input type="text"
                     class="form-control ng-untouched ng-pristine ng-valid  paddingU"
                     placeholder="News"
                     [(ngModel)]="searchSymbolFull"
                     (input)="changeFilterValue('market.SymbolFull', searchSymbolFull)" style="width:5em;">
              </td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let deal of deals   |
                  paginate: {  id: 'paginationList1',
                                   itemsPerPage: pageSize1,
                                   currentPage: page1,
                                   totalItems: count1 } ;
                  let i = index"
                [ngClass]="{'buyingOpened': deal.buySell == 'BUY' && deal.status == 'NEW',
                             'sellingOpened': deal.buySell == 'SELL' && deal.status == 'NEW'}">
<!--              <td class="text-center">{{(pageSize1 * (page1 - 1)) + i + 1}}</td>-->
              <td class="text-center"
                  [ngClass]="{'sellingRed': deal.buySell == 'SELL' && deal.status == 'NEW'}">{{deal.buySell}}
              </td>
              <td class="text-center">{{deal.status}}</td>
              <td class="text-center">
                <ng-container *ngIf="deal.market.Photo !== ''">
                  <a href="https://www.etoro.com/markets/{{deal.market.SymbolFull}}" target="_blank"
                     class="fs-5 text-dark text-hover-primary fw-bolder">
                    <img alt="Logo" src="{{deal.market.Photo}}" class="h-45px"/>
                  </a>
                </ng-container>
              </td>
              <td class="text-center">{{deal.market.SymbolFull}}</td>
              <td class="text-center">{{deal.priceMin}}</td>
              <td class="text-center">{{deal.priceMax}}</td>
              <td class="text-center">{{deal.rating}}</td>
              <td>
                <div>
                  <div class="text-md-start w-100"><a href="{{deal.news?.link1 || ''}}" width="100%"
                                                      target="_blank">{{deal.news?.title1 || ''}}</a></div>
                  <div class="text-md-start w-100"><a href="{{deal.news?.link2 || ''}}" width="100%"
                                                      target="_blank">{{deal.news?.title2 || ''}}</a></div>
                  <div class="text-md-start w-100"><a href="{{deal.news?.link3 || ''}}" width="100%"
                                                      target="_blank">{{deal.news?.title3 || ''}}</a></div>
                </div>
              </td>
              <td class="tableButton">
                <div class="d-flex justify-content-end flex-shrink-0">
                  <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                          (click)="doSubscribe(deal)" *ngIf="deal.userCount===0">
                    <span
                      [inlineSVG]="'./assets/media/icons/duotune/arrows/arr013.svg'"
                      class="svg-icon svg-icon-2">

                    </span>
                  </button>
                  <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                          (click)="doUnsubscribe(deal)" *ngIf="deal.userCount!=0">
                    <span
                      [inlineSVG]="'./assets/media/icons/duotune/arrows/arr014.svg'"
                      class="svg-icon svg-icon-2">

                    </span>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="pagination1">
        <pagination-controls
          id="paginationList1"
          previousLabel="Prev"
          nextLabel="Next"
          responsive="true"
          (pageChange)="handlePageChange1($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>

<div class="row py-1">
  <div class="col">
    <div class="card-body py-1">
      <h3>
        Yesterday new deals
      </h3>
      <div class="table">
        <table datatable class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2">
          <thead>
<!--          <tr class="fw-bolder text-muted">-->
<!--&lt;!&ndash;            <th class="text-center" style="width:7%" (click)="sortBy('index')">Index</th>&ndash;&gt;-->
<!--            <th class="text-center" style="width:8%" (click)="sortBy('buySell')">BuySell</th>-->
<!--            <th class="text-center" style="width:7%" (click)="sortBy('status')">Status</th>-->
<!--            <th class="text-center" style="width:5%">Icon</th>-->
<!--            <th class="text-center" style="width:8%" (click)="sortBy('market.SymbolFull')">Market</th>-->
<!--            <th class="text-center" style="width:7%" (click)="sortBy('priceMin')">Min></th>-->
<!--            <th class="text-center" style="width:7%" (click)="sortBy('priceMax')">Max></th>-->
<!--            <th class="text-center" style="width:8%" (click)="sortBy('rating')">Rating></th>-->
<!--            <th class="text-center">News</th>-->
<!--            <th scope="text-center" style="width:8%">Subscribe</th>-->
<!--          </tr>-->
          <tr>
<!--            <td>-->
<!--              <input type="text" class="form-control ng-untouched ng-pristine ng-valid paddingU"-->
<!--                     placeholder="Index">-->
<!--            </td>-->
            <td>
              <input type="text"
                     class="form-control ng-untouched ng-pristine ng-valid  paddingU"
                     placeholder="BuySell"
                     [(ngModel)]="searchByBuySell"
                     (input)="changeFilterValue('buySell', searchByBuySell)" style="width:5em;">
            </td>
            <td>
              <input type="text"
                     class="form-control ng-untouched ng-pristine ng-valid paddingU "
                     placeholder="Status"
                     [(ngModel)]="searchByStatus"
                     (input)="changeFilterValue('status', searchByStatus)" style="width:5em;">
            </td>
            <td>
            </td>
            <td>
              <input type="text"
                     class="form-control ng-untouched ng-pristine ng-valid  paddingU"
                     placeholder="Market"
                     [(ngModel)]="searchSymbolFull"
                     (input)="changeFilterValue('market.SymbolFull', searchSymbolFull)" style="width:5em;">
            </td>
            <td>
              <input type="number"
                     class="form-control ng-untouched ng-pristine ng-valid paddingU "
                     placeholder="min"
                     [(ngModel)]="searchSymbolPriceMin"
                     (input)="changeFilterValue('priceMin', searchSymbolPriceMin)" style="width:5em;">
            </td>
            <td>
              <input type="number"
                     class="form-control ng-untouched ng-pristine ng-valid  paddingU"
                     placeholder="max"
                     [(ngModel)]="searchSymbolPriceMax"
                     (input)="changeFilterValue('priceMax', searchSymbolPriceMax)" style="width:5em;">
            </td>
            <td>
              <input type="number"
                     class="form-control ng-untouched ng-pristine ng-valid paddingU "
                     placeholder="rate"
                     [(ngModel)]="searchRating"
                     (input)="changeFilterValue('rating', searchRating)" style="width:3em;">
            </td>
            <td>
              <input type="text"
                     class="form-control ng-untouched ng-pristine ng-valid  paddingU"
                     placeholder="News"
                     [(ngModel)]="searchSymbolFull"
                     (input)="changeFilterValue('market.SymbolFull', searchSymbolFull)">
            </td>
            <td></td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let deal of dealsY   |
                  paginate: {  id: 'paginationList1Y',
                                   itemsPerPage: pageSize1Y,
                                   currentPage: page1Y,
                                   totalItems: count1Y } ;
                  let i = index"
              [ngClass]="{'buyingOpened': deal.buySell == 'BUY' && deal.status == 'NEW',
                             'sellingOpened': deal.buySell == 'SELL' && deal.status == 'NEW'}">
<!--            <td>{{(pageSize1Y * (page1Y - 1)) + i + 1}}</td>-->
            <td
              [ngClass]="{'sellingRed': deal.buySell == 'SELL' && deal.status == 'NEW'}">{{deal.buySell}}
            </td>
            <td>{{deal.status}}</td>
            <td>
              <ng-container *ngIf="deal.market.Photo !== ''">
                <a href="https://www.etoro.com/markets/{{deal.market.SymbolFull}}" target="_blank"
                   class="fs-5 text-dark text-hover-primary fw-bolder">
                  <img alt="Logo" src="{{deal.market.Photo}}" class="h-45px"/>
                </a>
              </ng-container>
            </td>
            <td>{{deal.market.SymbolFull}}</td>
            <td>{{deal.priceMin}}</td>
            <td>{{deal.priceMax}}</td>
            <td>{{deal.rating}}</td>
            <td>
              <div>
                <div class="text-md-start w-100"><a href="{{deal.news?.link1 || ''}}" width="100%"
                                                    target="_blank">{{deal.news?.title1 || ''}}</a></div>
                <div class="text-md-start w-100"><a href="{{deal.news?.link2 || ''}}" width="100%"
                                                    target="_blank">{{deal.news?.title2 || ''}}</a></div>
                <div class="text-md-start w-100"><a href="{{deal.news?.link3 || ''}}" width="100%"
                                                    target="_blank">{{deal.news?.title3 || ''}}</a></div>
              </div>
            </td>
            <td class="tableButton">
              <div class="d-flex justify-content-end flex-shrink-0">
                <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                        (click)="doSubscribe(deal)" *ngIf="deal.userCount===0">
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/arrows/arr013.svg'"
                  class="svg-icon svg-icon-2"></span>
                </button>
                <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                        (click)="doUnsubscribe(deal)" *ngIf="deal.userCount!=0">
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/arrows/arr014.svg'"
                  class="svg-icon svg-icon-2"></span>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination2">
      <pagination-controls
        id="paginationList1Y"
        previousLabel="Prev"
        nextLabel="Next"
        responsive="true"
        (pageChange)="handlePageChange1Y($event)"
      ></pagination-controls>
    </div>
  </div>
</div>

<div hidden>
  <button id="kt_engage_demos_toggle"
          class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0"
          #premium
          title="" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-dismiss="click"
          data-bs-trigger="hover"
          data-bs-original-title="Check out 20 more demos">
    <span id="kt_engage_demos_label">PremiumPopupComponent</span>
  </button>
</div>

