import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {UserModel} from '../../../modules/auth';
import {AuthHTTPService} from '../../../modules/auth/services/auth-http';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  user: UserModel;
  roles = [
    {id: 1, name: 'ROLE_USER'},
    {id: 2, name: 'ROLE_ADMIN'},
  ];
  createMode = false;
  password: string;
  canCreatePassword: boolean;
  canChangePassword: boolean;
  mode: string;
  title: string;
  returnUrl: string;
  userId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private authService: AuthHTTPService,
              private changeDetector: ChangeDetectorRef) {
    this.user = new UserModel();
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['userId'] != null) {
      this.userId = this.route.snapshot.queryParams['userId'];
      this.mode = this.route.snapshot.queryParams['mode'];
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

      this.canCreatePassword = true;
      this.title = 'Create user';

      if (this.mode === 'create') {
        this.createMode = true;
        this.user = new UserModel();
      } else if (this.mode === 'edit') {
        this.userService.findById(this.userId)
          .toPromise()
          .then((user) => {
            if (user != null) {
              this.user = user;
              this.setTitleAndAccept();
              this.changeDetector.detectChanges();
            }
          })
          .catch((error) => {
            this.router.navigate(['/error'],
              {
                queryParams:
                  {
                    errorCode: error.status
                  }
              });
          });
      }
    }
  }

  private setTitleAndAccept(): void {
    if (this.mode === 'edit') {
      this.canCreatePassword = true;
      this.canChangePassword = true;
      this.title = 'Edit user: ' + this.user.username;
    }
    if (this.mode === 'editProfile') {
      this.title = 'Profile user: ' + this.user.username;
    }
  }

  save(): void {
    if (this.createMode) {
      this.user.password = this.password;
      this.authService.registerUser(this.user)
        .toPromise()
        .then(data => {
          if (data != null && data.httpStatus === 'OK') {
            this.router.navigate(['/userList'],
              {
                queryParams:
                  {
                    messageData: data.answer
                  }
              });
          }
        }, error => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    } else {
      this.userService.saveUser(this.user)
        .toPromise()
        .then(data => {
          if (data != null) {
            this.router.navigate(['/userList'],
              {
                queryParams:
                  {
                    messageData: 'User ' + this.user.email + ' is saved.'
                  }
              });
          }
        }, error => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    }
  }

  back(): void {
    if (this.returnUrl) {
      const massive = this.returnUrl.split(':');
      this.router.navigate([massive[0]],
        {
          queryParams: {
            paymentId: massive[1],
            mode: 'edit'
          }
        });
    } else {
      this.router.navigate(['/userList']);
    }
  }

  changePassword(): void {
    if (this.password === '') {
      alert('Password is empty!');
    } else {
      this.userService.changePassword(this.user, this.password)
        .toPromise()
        .then(data => {
          if (data != null && data.httpStatus === 'OK') {
            alert('Password was changed!');
          }
        }, error => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    }
  }
}
