import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Quote} from '../../../model/quote';
import {QuoteService} from '../../../services/quote.service';
import {PageQuoteRequest} from "../../../model/paging/pageQuoteRequest";
import {PageResponse} from "../../../model/paging/pageResponse";

const NAME = 'Quote';

@Component({
  selector: 'app-quote',
  templateUrl: './quotelist.component.html',
  styleUrls: ['./quotelist.component.css']
})
export class QuotelistComponent implements OnInit {

  private ascDirection = 1;

  page = 0;
  pagePaging = 1;
  count = 0;
  pageSize = 20;

  traderId: string;
  traderName: string;
  quotes: Quote[];
  title: string;

  pageRequest: PageQuoteRequest;

  // filter field
  positionID: any;
  marketId: any;
  marketName: any;
  marketDisplayName: any;
  openDateTime: any;
  openRate: any;
  buySell: any;
  takeProfitRate: any;
  stopLossRate: any;
  amount: any;
  currentRate: any;
  netProfit: any;
  leverage: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private quoteService: QuoteService) {
    this.title = 'Quotes';
  }

  ngOnInit(): void {
    this.pageRequest = new PageQuoteRequest(this.page, this.pageSize, this.ascDirection);

    this.traderId = this.route.snapshot.queryParams['traderId'];
    this.traderName = this.route.snapshot.queryParams['traderName'];

    this.reloadData();
  }

  reloadData(): void {
    if (this.traderId && this.traderName) {
      this.loadQuotesByTrader();
    } else {
      this.loadAllQuotes();
    }
  }

  loadQuotesByTrader(): void {
    this.quoteService.findByTrader(this.traderId, this.pageRequest)
      .toPromise()
      .then((pageResponse: PageResponse) => {
        this.quotes = pageResponse.content;
        this.title = NAME + ' [' + this.quotes.length + ']' + ' by ' + this.traderName;
        this.count = pageResponse.totalElements;
        this.changeDetector.detectChanges();
      });
  }

  private loadAllQuotes(): void {
    this.quoteService.findAllQuotes(this.pageRequest)
      .toPromise()
      .then((pageResponse: PageResponse) => {
        this.quotes = pageResponse.content;
        this.count = pageResponse.totalElements;
        this.changeDetector.detectChanges();
      });
  }

  handlePageChange(event: number): void {
    this.pagePaging = event;
    this.pageRequest.page = event - 1;
    this.reloadData();
  }

  sortBy(criteria: string): void {
    this.pageRequest.criteria = criteria;

    if (this.pageRequest.ascDirection === -1) {
      this.pageRequest.ascDirection = 1;
    } else {
      this.pageRequest.ascDirection = -1;
    }

    this.reloadData();
  }

  changeFilterValue(column: string, value: any): void {
    switch (column) {
      case 'PositionID':
        this.pageRequest.positionID = value;
        break;
      case 'marketId':
        this.pageRequest.marketId = value;
        break;
      case 'marketName':
        this.pageRequest.marketName = value;
        break;
      case 'marketDisplayName':
        this.pageRequest.marketDisplayName = value;
        break;
      case 'OpenDateTime':
        this.pageRequest.openDateTime = value;
        break;
      case 'openRate':
        this.pageRequest.openRate = value;
        break;
      case 'buySell':
        this.pageRequest.buySell = value;
        break;
      case 'takeProfitRate':
        this.pageRequest.takeProfitRate = value;
        break;
      case 'stopLossRate':
        this.pageRequest.stopLossRate = value;
        break;
      case 'amount':
        this.pageRequest.amount = value;
        break;
      case 'currentRate':
        this.pageRequest.currentRate = value;
        break;
      case 'netProfit':
        this.pageRequest.netProfit = value;
        break;
      case 'leverage':
        this.pageRequest.leverage = value;
        break;
    }

    this.reloadData();
  }
}
