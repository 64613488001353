import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MarketService} from '../../../services/market.service';
import {Market} from '../../../model/market';
import {ModalWindowComponent} from '../../dropdown-menu1/modal-window.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {PageResponse} from '../../../model/paging/pageResponse';
import {PageMarketRequest} from '../../../model/paging/pageMarketRequest';

const NAME = 'Market';

@Component({
  selector: 'app-market',
  templateUrl: './marketlist.component.html',
  styleUrls: ['./marketlist.component.css']
})
export class MarketlistComponent implements OnInit {

  private ascDirection = 1;

  traderId: string;
  traderName: string;
  markets: Market[];
  title: string;

  page = 0;
  pagePaging = 1;
  count = 0;
  pageSize = 20;

  pageRequest: PageMarketRequest;

  instrumentID: any;
  symbolFull: any;
  searchEnable: any;
  instrumentDisplayName: any;
  typeId: string;
  priceSource: any;

  alert = false;
  alertMessage: string;
  criteria: string;

  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private marketService: MarketService,
              private changeDetector: ChangeDetectorRef,
              private modalService: NgbModal) {
    this.title = NAME;
  }

  ngOnInit(): void {
    this.pageRequest = new PageMarketRequest(this.page, this.pageSize, this.ascDirection);
    if (this.route.snapshot.queryParams['messageData'] != null) {
      this.viewMessage(this.route.snapshot.queryParams['messageData']);
    }
    if (this.route.snapshot.queryParams['traderId'] != null) {
      this.traderId = this.route.snapshot.queryParams['traderId'];
      this.traderName = this.route.snapshot.queryParams['traderName'];
    }

    this.reloadData();
  }

  private reloadData(): void {
    if (this.traderId && this.traderName) {
      this.loadMarketByTrader();
    } else {
      this.loadAllMarket();
    }
  }

  loadMarketByTrader(): void {
    this.marketService.findByTrader(this.traderId, this.pageRequest)
      .toPromise()
      .then((pageResponse: PageResponse) => {
        this.markets = pageResponse.content;
        this.title = NAME + ' [' + pageResponse.content.length + ']' + ' by ' + this.traderName;
        this.count = pageResponse.content.length;
        this.changeDetector.detectChanges();
      });
  }

  handlePageChange(event: number): void {
    this.pagePaging = event;
    this.pageRequest.page = event - 1;
    this.reloadData();
  }

  private loadAllMarket(): void {
    this.marketService.findAllMarket(this.pageRequest)
      .toPromise()
      .then((pageResponse: PageResponse) => {
        this.count = pageResponse.totalElements;
        this.markets = pageResponse.content;
        this.changeDetector.detectChanges();
      });
  }

  sortBy(criteria: string): void {
    switch (criteria) {
      case 'InstrumentID':
        this.pageRequest.criteria = 'id';
        break;
      case 'SymbolFull':
        this.pageRequest.criteria = 'name';
        break;
      case 'InstrumentDisplayName':
        this.pageRequest.criteria = 'description';
        break;
      case 'TypeId':
        this.pageRequest.criteria = 'typeId';
        break;
      case 'PriceSource':
        this.pageRequest.criteria = 'priceSource';
        break;
    }

    if (this.pageRequest.ascDirection === -1) {
      this.pageRequest.ascDirection = 1;
    } else {
      this.pageRequest.ascDirection = -1;
    }

    if (this.traderId == null) {
      this.loadAllMarket();
    } else {
      this.loadMarketByTrader();
    }
  }

  changeFilterValue(column: string, value: string): void {
    switch (column) {
      case 'InstrumentID':
        this.pageRequest.instrumentID = value;
        break;
      case 'Enable':
        this.pageRequest.searchEnable = value;
        break;
      case 'SymbolFull':
        this.pageRequest.symbolFull = value;
        break;
      case 'InstrumentDisplayName':
        this.pageRequest.instrumentDisplayName = value;
        break;
      case 'TypeId':
        this.pageRequest.typeId = value;
        break;
      case 'PriceSource':
        this.pageRequest.priceSource = value;
        break;
    }

    if (this.traderId == null) {
      this.loadAllMarket();
    } else {
      this.loadMarketByTrader();
    }
  }

  private makeQuestion(question: string): void {
    this.modalRef = this.modalService.open(ModalWindowComponent);
    this.modalRef.componentInstance.my_modal_title = 'Question';
    this.modalRef.componentInstance.my_modal_content = question;
  }

  viewMessage(message: string): void {
    this.alert = true;
    this.alertMessage = message;
    setTimeout(() => {
      this.alert = false;
      this.changeDetector.detectChanges();
    }, 3000);
  }

  edit(instrumentID: number): void {
    this.router.navigate(['/market'],
      {
        queryParams: {
          id: instrumentID,
          mode: 'edit'
        }
      });
  }

  delete(instrumentID: number): void {
    this.makeQuestion('User will remove. Are you agree?');
    this.modalRef.result.then((result) => {
      console.log('false');
    }, (reason) => {
      this.marketService.delete(instrumentID)
        .toPromise()
        .then((result) => {
          this.reloadData();
          this.viewMessage(result.answer);
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    });
  }
}
