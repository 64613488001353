import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
})
export class ModalWindowComponent implements OnInit {
  // @HostBinding('class') class =
  //   'menu menu-sub menu-sub-dropdown w-250px w-md-300px';
  // @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  @Input() my_modal_title: string;
  @Input() my_modal_content: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
