import {Product} from './product';
import {UserModel} from '../../modules/auth';

export class Payment {
  id: number;
  product: Product;
  startPeriod: Date;
  endPeriod: Date;
  name: string;
  subTotal: number;
  tax: number;
  total: number;
  month: number;
  currency: string;
  paymentSource: string;
  stripePriceId: string;
  subscriptionId: string;
  user: UserModel;
}
