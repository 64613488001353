import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Trader} from '../../../model/trader';
import {Parser} from '../../../model/parser';
import {TraderService} from '../../../services/trader.service';
import {ParserService} from '../../../services/parser.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-trader-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class TraderEditComponent implements OnInit, OnDestroy {

  private unsubscribe: Subscription[] = [];

  trader: Trader;
  id: number;
  parsers: Parser[];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private traderService: TraderService,
              private parserService: ParserService,
              private changeDetector: ChangeDetectorRef) {
    this.trader = new Trader();
    this.trader.parser = new Parser();
  }

  ngOnInit(): void {
    this.loadAllParsers();
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.traderService.findById(this.id)
        .toPromise()
        .then((data) => {
          if (data != null) {
            this.trader = data;
            this.changeDetector.detectChanges();
          }
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    }
  }

  private loadAllParsers(): void {
    this.parserService.findAllParsers()
      .toPromise()
      .then((data) => {
        if (data != null) {
          this.parsers = data;
          this.changeDetector.detectChanges();
        }
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  save(): void {
    this.traderService.saveTrader(this.trader)
      .toPromise()
      .then((result) => {
        this.router.navigate(['/traderlist']);
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  back(): void {
    this.router.navigate(['/traderlist']);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
