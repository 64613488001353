import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TraderService} from '../../../services/trader.service';
import {Trader} from '../../../model/trader';
import {ModalWindowComponent} from '../../dropdown-menu1/modal-window.component';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {PageTraderRequest} from '../../../model/paging/pageTraderRequest';
import {PageResponse} from '../../../model/paging/pageResponse';

@Component({
  selector: 'app-trader',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class TraderListComponent implements OnInit {

  private ascDirection = 1;

  title: string;
  traders: Trader[];

  page = 0;
  pagePaging = 1;
  count = 0;
  pageSize = 20;

  pageRequest: PageTraderRequest;

  searchId: any;
  searchName: any;
  searchParser: any;
  searchRating: any;

  alert = false;
  alertMessage: string;

  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private traderService: TraderService,
              private changeDetector: ChangeDetectorRef,
              private modalService: NgbModal) {
    this.title = 'Traders';
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit(): void {
    this.pageRequest = new PageTraderRequest(this.page, this.pageSize, this.ascDirection);
    this.reloadData();
    if (this.route.snapshot.queryParams['messageData'] != null) {
      this.viewMessage(this.route.snapshot.queryParams['messageData']);
    }
  }

  viewMessage(message: string): void {
    this.alert = true;
    this.alertMessage = message;
    setTimeout(() => {
      this.alert = false;
      this.changeDetector.detectChanges();
    }, 3000);
  }

  reloadData(): void {
    this.traderService.findAll(this.pageRequest)
      .subscribe((pageResponse: PageResponse) => {
        this.count = pageResponse.totalElements;
        this.traders = pageResponse.content;
        this.changeDetector.detectChanges();
      });
  }

  marketsByTrader(trader: Trader): void {
    this.router.navigate(['/marketlist'],
      {
        queryParams:
          {
            traderId: trader.id,
            traderName: trader.name
          }
      });
  }

  quotesByTrader(trader: Trader): void {
    this.router.navigate(['/quotelist'],
      {
        queryParams: {
          traderId: trader.id,
          traderName: trader.name
        }
      });
  }

  handlePageChange(event: number): void {
    this.pagePaging = event;
    this.pageRequest.page = event - 1;
    this.reloadData();
  }

  edit(id: number): void {
    this.router.navigate(['trader', id]);
  }

  delete(id: number): void {
    this.makeQuestion('Trader will remove. Are you agree?');
    this.modalRef.result.then((result) => {
      console.log('false');
    }, (reason) => {
      this.traderService.deleteTrader(id)
        .toPromise()
        .then((result) => {
          this.reloadData();
          this.viewMessage(result.answer);
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    });
  }

  private makeQuestion(question: string): void {
    this.modalRef = this.modalService.open(ModalWindowComponent);
    this.modalRef.componentInstance.my_modal_title = 'Question';
    this.modalRef.componentInstance.my_modal_content = question;
  }

  sortBy(criteria: string): void {
    this.pageRequest.criteria = criteria;

    if (this.pageRequest.ascDirection === -1) {
      this.pageRequest.ascDirection = 1;
    } else {
      this.pageRequest.ascDirection = -1;
    }

    this.reloadData();
  }

  changeFilterValue(column: string, value: any): void {
    switch (column) {
      case 'id':
        this.pageRequest.searchId = value;
        break;
      case 'name':
        this.pageRequest.searchName = value;
        break;
      case 'parser.id':
        this.pageRequest.searchParser = value;
        break;
      case 'rating':
        this.pageRequest.searchRating = value;
        break;
    }
    this.reloadData();
  }
}
