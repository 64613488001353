import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ResponseModel} from '../model/response-model';
import {Payment} from '../model/paypal/payment';
import {environment} from 'src/environments/environment';

const API_USERS_URL = `${environment.apiUrl}/payment`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) {
  }

  findAll(): Observable<Payment[]> {
    return this.http.get<Payment[]>(`${API_USERS_URL}`, httpOptions);
  }

  findById(id: number): Observable<Payment> {
    return this.http.get<Payment>(`${API_USERS_URL}/` + id, httpOptions);
  }

  save(payment: Payment): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/save`, payment, httpOptions);
  }

  delete(id: number): Observable<ResponseModel> {
    return this.http.delete<ResponseModel>(`${API_USERS_URL}/` + id, httpOptions);
  }
}
