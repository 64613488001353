import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ICreateSubscriptionRequest, IPayPalConfig, PayPalScriptService} from 'ngx-paypal';
import {Product} from '../../../model/paypal/product';
import {Payment} from '../../../model/paypal/payment';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {ProductService} from '../../../services/product.service';
import {PaymentService} from '../../../services/payment.service';
import {AuthService, UserModel} from '../../auth';
import {loadStripe} from '@stripe/stripe-js';

@Component({
  selector: 'app-paypal',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public payPalConfig?: IPayPalConfig;
  products: Product[];
  payment: Payment;
  userEmail: string;
  public showSuccess = false;
  public showCancel = false;
  public showError = false;

  public trialHasEndedMessage = 'Trial of 3 days has ended!';
  trialHasEnded = false;

  alert = false;
  alertMessage: string;

  stripePromise = loadStripe(environment.stripePublicKey);

  constructor(private router: Router,
              private route: ActivatedRoute,
              private payPalScriptService: PayPalScriptService,
              private productService: ProductService,
              private paymentService: PaymentService,
              private authService: AuthService,
              private changeDetector: ChangeDetectorRef) {
    this.payment = new Payment();
    this.payment.product = new Product();
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['marker']) {
      const marker = this.route.snapshot.queryParams['marker'];
      if (marker === this.trialHasEndedMessage) {
        this.trialHasEnded = true;
      }
    }

    this.loadAllProducts();
    this.authService.getUserByToken()
      .toPromise()
      .then(user => {
        if (user) {
          this.userEmail = user.email;
          this.changeDetector.detectChanges();
        }
      });
  }

  private initConfig(): void {
    this.payPalConfig = {
      clientId: environment.paypalClientId,
      currency: this.payment.currency,
      vault: 'true',
      createSubscriptionOnClient: (data: any) => <ICreateSubscriptionRequest>{
        plan_id: 'P-648745202W021174RMVO5U2I',
        quantity: 1
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'pay',
        layout: 'vertical'
      },
      onApprove: (data: any, actions: any) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details: any) => {
          this.showSuccess = true;
          this.savePayment(details);
          console.log('onApprove - you can get full order details inside onApprove: ', details);
          this.router.navigate(['/deals'], {
            queryParams: {},
          });
        });

      },
      onClientAuthorization: (data: any) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
      },
      onCancel: (data: any, actions: any) => {
        // console.log('OnCancel', data, actions);
        this.showCancel = true;
        // alert('Payment has canceled!');
      },
      onError: (err: any) => {
        // console.log('OnError', err);
        this.showError = true;
        // alert('Error. Has a problem!');
      },
      onClick: (data: any, actions: any) => {
        // console.log('onClick', data, actions);
        this.resetStatus();
      },
      onInit: (data: any, actions: any) => {
        // console.log('onInit', data, actions);
      }
    };
  }

  savePayment(details: any): void {
    const user = new UserModel();
    user.email = this.userEmail;

    const newPayment = new Payment();
    newPayment.name = this.payment.name;
    newPayment.month = this.payment.month;
    newPayment.subTotal = this.payment.subTotal;
    newPayment.tax = this.payment.tax;
    newPayment.total = this.payment.total;
    newPayment.currency = this.payment.currency;
    newPayment.paymentSource = 'PAYPAL';
    newPayment.stripePriceId = this.payment.stripePriceId;
    newPayment.user = user;

    this.paymentService.save(newPayment)
      .toPromise()
      .then((result) => {
        alert(result.answer);
        this.router.navigate(['/paymentList']);
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  private resetStatus(): void {
    this.showError = false;
    this.showSuccess = false;
    this.showCancel = false;
  }

  private viewMessage(message: string): void {
    this.alert = true;
    this.alertMessage = message;
    setTimeout(() => {
      this.alert = false;
      this.changeDetector.detectChanges();
    }, 3000);
  }

  private loadAllProducts(): void {
    this.productService.findActiveProducts()
      .toPromise()
      .then((data) => {
        if (data != null) {
          if (this.trialHasEnded) {
            const products = data.filter(product => product.name.includes('*'));
            this.products = products;

            this.payment = new Payment();
            this.payment.product = products[0];

            this.viewMessage(this.trialHasEndedMessage);
            console.log(this.trialHasEndedMessage);

          } else {
            this.products = data;

            this.payment = new Payment();
            this.payment.product = this.products[0];
          }

          this.onChangeProduct();
          this.changeDetector.detectChanges();
        }
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  onChangeProduct(): void {
    if (this.payment) {
      this.payment.name = this.payment.product.name;
      this.payment.currency = this.payment.product.currency;
      this.payment.subTotal = this.payment.product.subTotal;
      this.payment.month = this.payment.product.month;
      this.payment.tax = this.payment.product.tax;
      this.payment.stripePriceId = this.payment.product.stripePriceId;
      this.payment.total = Number((this.payment.product.subTotal + this.payment.product.tax).toFixed(2));

      this.initConfig();
    }
  }

  async checkout() {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await this.stripePromise;

    if (stripe) {
      const {error} = await stripe.redirectToCheckout({
        mode: 'subscription',
        customerEmail: this.authService.currentUserValue?.email,
        lineItems: [{price: this.payment.stripePriceId, quantity: 1}],
        successUrl: environment.url + '/paymentSuccess?stripePriceId=' + this.payment.stripePriceId +
          '&clientId=' + this.authService.currentUserValue?.id +
          '&productId=' + this.payment.product.id,
        cancelUrl: environment.url + '/paymentError',
      });

      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      if (error) {
        console.log(error);
      }
    }
  }
}
