import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Deal} from '../../../model/deal';
import {DealService} from '../../../services/deal.service';
import {ResponseModel} from '../../../model/response-model';
import {AuthService} from '../../../modules/auth';
import {environment} from '../../../../environments/environment';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NewsService} from '../../../services/news.service';
import {ModalWindowComponent} from '../../dropdown-menu1/modal-window.component';

@Component({
  selector: 'app-submarket',
  templateUrl: './deal.component.html',
  styleUrls: ['./deal.component.css']
})
export class DealComponent implements OnInit {

  @ViewChild('premium') premium: ElementRef;

  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private ascDirection = 1;

  responseModel: ResponseModel;

  latestNews: string;
  title: string;
  deals: Deal[];
  deals2: Deal[];

  dealsY: Deal[];
  deals2Y: Deal[];

  userEmail: string;

  page1 = 1;
  count1 = 0;
  pageSize1 = 10;

  page2 = 1;
  count2 = 0;
  pageSize2 = 5;

  page1Y = 1;
  count1Y = 0;
  pageSize1Y = 10;

  page2Y = 1;
  count2Y = 0;
  pageSize2Y = 5;

  sortByBuySell: boolean;
  sortByStatus: boolean;
  sortBySymbolFull: boolean;
  sortByRating: boolean;
  sortBySymbolPriceMin: boolean;
  sortBySymbolPriceMax: boolean;

  searchByBuySell: any;
  searchByStatus: any;
  searchSymbolFull: any;
  searchRating: number;
  searchSymbolPriceMin: number;
  searchSymbolPriceMax: number;

  secondsCount: number;
  updateEnable: boolean;
  dayForFilter: number;
  dateFrom: string;
  dateTo: string;
  priceMin: number;
  priceMax: number;
  rating: number;

  criteria: string;

  columnFilter: string;
  valueFilter: string;

  alert = false;
  alertMessage: string;
  timerId: any;

  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dealService: DealService,
              private authService: AuthService,
              private newsService: NewsService,
              private changeDetector: ChangeDetectorRef,
              private modalService: NgbModal) {
    this.title = 'Real time deals';
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit(): void {
    const user = this.authService.currentUserValue;
    if (user) {
      if (user.trialExpired) {
        const warn = 'Trial of 3 days has ended!';

        this.router.navigate(['/payment'],
          {
            queryParams:
              {
                marker: warn
              }
          });
      }
    }

    this.secondsCount = 30;
    this.updateEnable = true;
    this.dayForFilter = 1;

    this.dateFrom = new Date().toISOString().slice(0, 10);
    this.dateTo = new Date().toISOString().slice(0, 10);

    if (this.route.snapshot.queryParams.messageData != null) {
      this.viewMessage(this.route.snapshot.queryParams.messageData);
    }

    console.log('From: ' + this.dateFrom + ' - ' + ' To: ' + this.dateTo);

    this.authService.getUserByToken()
      .toPromise()
      .then(user => {
        if (user) {
          this.userEmail = user.email;
          this.reloadDataByUser();
          this.timerId = setInterval(() => this.reloadDataByUser(), this.secondsCount * 1000);
        }
      });
  }

  private viewMessage(message: string): void {
    this.alert = true;
    this.alertMessage = message;
    setTimeout(() => {
      this.alert = false;
      this.changeDetector.detectChanges();
    }, 3000);
  }

  private showNews(marketSymbol: string): void {
    this.newsService.findNews(marketSymbol)
      .toPromise()
      .then(responseModel => {
        if (responseModel.httpStatus === 'OK') {
          this.modalRef = this.modalService.open(ModalWindowComponent);
          this.modalRef.componentInstance.my_modal_title = 'Latest news';
          this.modalRef.componentInstance.my_modal_content = responseModel.model;
        }
      }, (error) => {
        localStorage.removeItem(this.authLocalStorageToken);
        window.location.href = '/auth/login';
      });
  }

  reloadDataByUser(): void {
    if (this.userEmail != null) {
      this.dealService.findByUserIdAndDayFilter(this.userEmail, new Date(this.dateFrom).toISOString(), new Date(new Date(this.dateTo).setUTCHours(23, 59, 59, 999)).toISOString())
        .toPromise()
        .then(response => {
          if (response && response.httpStatus !== 'NOT_FOUND') {
            console.log('Update reload every ' + this.secondsCount);

            // Today
            let temp: Deal[] = [];
            temp = JSON.parse(response.model.today);
            // temp = this.parse(temp);
            temp = this.sorting(temp);
            temp = this.filter(temp);

            this.deals = this.filterBy(temp, 'NEW');
            if (this.deals == null) {
              this.deals = [];
            }
            // this.deals2 = this.filterBy(temp, 'CLOSE');
            // if (this.deals2 == null) {
            //   this.deals2 = [];
            // }

            this.count1 = this.deals.length;
            // this.count2 = this.deals2.length;

            // Yesterday
            let tempY: Deal[] = [];
            tempY = JSON.parse(response.model.yesterday);
            tempY = this.sorting(tempY);
            tempY = this.filter(tempY);

            this.dealsY = this.filterBy(tempY, 'NEW');
            if (this.dealsY == null) {
              this.dealsY = [];
            }
            // this.deals2Y = this.filterBy(tempY, 'CLOSE');
            // if (this.deals2Y == null) {
            //   this.deals2Y = [];
            // }

            this.count1Y = this.dealsY.length;
            // this.count2Y = this.deals2Y.length;

            this.changeDetector.detectChanges();

          } else {
            this.count1 = 0;
            this.count2 = 0;

            this.count1Y = 0;
            this.count2Y = 0;

            this.viewMessage('No data');
          }
        }, (error) => {
          localStorage.removeItem(this.authLocalStorageToken);
          window.location.href = '/auth/login';
        });
    }
  }

  handlePageChange1(event: number): void {
    this.page1 = event;
  }

  handlePageChange1Y(event: number): void {
    this.page1Y = event;
  }

  sortBy(criteria: string): void {
    if (this.ascDirection === 1) {
      this.ascDirection = -1;
    } else {
      this.ascDirection = 1;
    }

    switch (criteria) {
      case 'buySell':
        this.sortByBuySell = true;
        break;
      case 'status':
        this.sortByStatus = true;
        break;
      case 'market.SymbolFull':
        this.sortBySymbolFull = true;
        break;
      case 'priceMin':
        this.sortBySymbolPriceMin = true;
        break;
      case 'priceMax':
        this.sortBySymbolPriceMax = true;
        break;
      case 'rating':
        this.sortByRating = true;
        break;
      default:
        break;

    }
    this.criteria = criteria;
    this.reloadDataByUser();
  }

  changeFilterValue(column: string, value: any): void {
    this.columnFilter = column;
    this.valueFilter = value;
    this.reloadDataByUser();
  }

  doSubscribe(subMarket: Deal): void {
    this.dealService.doSubscribe(subMarket.id, this.userEmail)
      .toPromise()
      .then(data => {
          if (data && data.httpStatus === 'OK') {
            this.viewMessage(data.answer);
            this.reloadDataByUser();
          } else if (data && data.httpStatus === 'CREATED') {
            this.viewMessage('No access without premium');
            this.premium.nativeElement.click();
          }
          console.log(data.answer);
        }, error => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        }
      );
  }

  doUnsubscribe(subMarket: Deal): void {
    this.dealService.doUnsubscribe(subMarket.id, this.userEmail)
      .toPromise()
      .then(data => {
          this.viewMessage(data.answer);
          this.reloadDataByUser();
        }, error => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        }
      );
  }

  onCheckboxChange(secondsCount: number): void {
    if (this.updateEnable) {
      this.timerId = setInterval(() => this.reloadDataByUser(), this.secondsCount * 1000);
    } else {
      clearInterval(this.timerId);
    }
    console.log('change checkbox timerId: ' + this.timerId);
  }

  updateLoadingPeriod(): void {
    if (this.updateEnable) {
      if (this.secondsCount < 5) {
        this.viewMessage('Minimal value for timer is 5');
        this.secondsCount = 5;
      }
      clearInterval(this.timerId);
      this.timerId = setInterval(() => this.reloadDataByUser(), this.secondsCount * 1000);
    }
  }

  createDeals(): void {
    this.dealService.createAll().subscribe(value => {
        this.responseModel = value;
      }, error => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      }
    );
  }

  updateDeals(): void {
    this.reloadDataByUser();
  }

  // private parse(deals: Deal[]): Deal[] {
  //   let htmlResult = '';
  //   if (deals) {
  //     deals.forEach((deal) => {
  //       if (deal.news && deal.news.news.length > 0) {
  //         htmlResult = htmlResult + '<div>';
  //         for (var i = 0; i < deal.news.news.length; i++) {
  //           htmlResult = htmlResult + '<div class="text-md-start w-100">' +
  //             deal.news.news[i] + '</div>';
  //           ;
  //         }
  //         htmlResult = htmlResult + '</div>';
  //
  //         deal.newsHtml = htmlResult;
  //       } else {
  //         deal.newsHtml = '';
  //       }
  //     });
  //   }
  //
  //   return deals;
  // }

  private sorting(deals: Deal[]): Deal[] {
    if (this.ascDirection === -1) {
      if (this.sortByBuySell) {
        deals.sort((a, b) => a.buySell.localeCompare(b.buySell));
      }
      if (this.sortByStatus) {
        deals.sort((a, b) => a.status.localeCompare(b.status));
      }
      if (this.sortBySymbolFull) {
        deals.sort((a, b) => a.market.SymbolFull.localeCompare(b.market.SymbolFull));
      }
      if (this.sortBySymbolPriceMin) {
        deals.sort((a, b) =>
          a.priceMin > b.priceMin ? 1 : -1);
      }
      if (this.sortBySymbolPriceMax) {
        deals.sort((a, b) => a.priceMax > b.priceMax ? 1 : -1);
      }
      if (this.sortByRating) {
        deals.sort((a, b) => (a.rating != null ? a.rating : Infinity) - (b.rating != null ? b.rating : Infinity));
      }
    } else {
      if (this.sortByBuySell) {
        deals.sort((a, b) => b.buySell.localeCompare(a.buySell));
      }
      if (this.sortByStatus) {
        deals.sort((a, b) => b.status.localeCompare(a.status));
      }
      if (this.sortBySymbolFull) {
        deals.sort((a, b) => b.market.SymbolFull.localeCompare(a.market.SymbolFull));
      }
      if (this.sortBySymbolPriceMin) {
        deals.sort((a, b) => b.priceMin > a.priceMin ? 1 : -1);
      }
      if (this.sortBySymbolPriceMax) {
        deals.sort((a, b) => b.priceMax > a.priceMax ? 1 : -1);
      }
      if (this.sortByRating) {
        deals.sort((a, b) => (b.rating != null ? b.rating : -Infinity) - (a.rating != null ? a.rating : -Infinity));
      }
    }

    return deals;
  }

  private filter(deals: Deal[]): Deal[] {

    if (this.columnFilter !== undefined && this.valueFilter !== undefined) {
      if (this.searchByBuySell != null) {
        deals = deals.filter(item => item.buySell.toLowerCase().includes(this.searchByBuySell.toLowerCase()));
      }
      if (this.searchByStatus != null) {
        deals = deals.filter(item => item.status.toLowerCase().includes(this.searchByStatus.toLowerCase()));
      }
      if (this.searchSymbolFull != null) {
        deals = deals.filter(item => item.market.SymbolFull.toLowerCase().includes(this.searchSymbolFull.toLowerCase()));
      }
      if (this.searchSymbolPriceMin != null) {
        deals = deals.filter(item => item.priceMin > this.searchSymbolPriceMin);
      }
      if (this.searchSymbolPriceMax != null) {
        deals = deals.filter(item => item.priceMax > this.searchSymbolPriceMax);
      }
      if (this.searchRating != null) {
        deals = deals.filter(item => item.rating > this.searchRating);
      }
    }

    return deals;
  }

  private filterBy(deals: Deal[], value: string): Deal[] {
    if (value === 'NEW' && deals != null) {
      deals = deals.filter(item => item.status.includes('NEW'));
    }
    if (value === 'CLOSE' && deals != null) {
      deals = deals.filter(item => item.status.includes('CLOSE'));
    }

    return deals;
  }

  onCheckboxSubscribedChange(): void {
    this.reloadDataByUser();
  }
}

