<div [hidden]="!alert" class="alert alert-primary" role="alert">
  {{alertMessage}}
</div>

<div class="card mb-5 mb-xl-8">

  <div class="card-body py-8">
    <div class="table-responsive">
      <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
        <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-10px" align="left">Index</th>
          <th class="min-w-10px" (click)="sortBy('InstrumentID')" align="left">Id</th>
          <th class="min-w-20px" align="left">Photo</th>
          <th class="min-w-10px" (click)="sortBy('Enable')" align="left">Enable</th>
          <th class="min-w-10px" (click)="sortBy('SymbolFull')" align="left">Symbol</th>
          <th class="min-w-50px" (click)="sortBy('InstrumentDisplayName')">Description</th>
          <th class="min-w-20px" (click)="sortBy('TypeId')">Type</th>
          <th class="min-w-20px" (click)="sortBy('PriceSource')">Source</th>
          <th class="min-w-100px text-end" scope="col" colspan="4">Actions</th>
        </tr>
        <tr>
          <td>
            <input type="text" class="form-control ng-untouched ng-pristine ng-valid" placeholder="Index">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Id"
                   [(ngModel)]="instrumentID"
                   (input)="changeFilterValue('InstrumentID', instrumentID)">
          </td>
          <td>
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Enable"
                   [(ngModel)]="searchEnable"
                   (input)="changeFilterValue('Enable', searchEnable)">
          </td>
          <td><input type="text"
                     class="form-control ng-untouched ng-pristine ng-valid"
                     placeholder="Symbol"
                     [(ngModel)]="symbolFull"
                     (input)="changeFilterValue('SymbolFull', symbolFull)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Description"
                   [(ngModel)]="instrumentDisplayName"
                   (input)="changeFilterValue('InstrumentDisplayName', instrumentDisplayName)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Source"
                   [(ngModel)]="typeId"
                   (input)="changeFilterValue('TypeId', typeId)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Source"
                   [(ngModel)]="priceSource"
                   (input)="changeFilterValue('PriceSource', priceSource)">
          </td>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let market of markets |
                  paginate: { itemsPerPage: pageSize,
                                   currentPage: pagePaging,
                                   totalItems: count } ;
                  let i = index">
          <td>{{(pageSize * (pagePaging - 1)) + i + 1}}</td>
          <td>{{market.InstrumentID}}</td>
          <td>
            <ng-container *ngIf="market.Photo !== ''">
              <a href="https://www.etoro.com/markets/{{market.SymbolFull}}" target="_blank"
                 class="fs-5 text-dark text-hover-primary fw-bolder">
                <img alt="Logo" src="{{market.Photo}}" class="h-45px"/>
              </a>
            </ng-container>
          </td>
          <td>
            <label class="checkbox checkbox-lg checkbox-inline">
              <input type="checkbox"
                     class="form-check-input"
                     [checked]="market.enable"
                     disabled>
              <span></span>
            </label>
          </td>
          <td>{{market.SymbolFull}}</td>
          <td>{{market.InstrumentDisplayName}}</td>
          <td>{{market.TypeId}}</td>
          <td>{{market.PriceSource}}</td>
          <td>
            <div class="d-flex justify-content-end flex-shrink-0">
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="edit(market.InstrumentID)"
                      title="Edit the market">
              <span
                [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                class="svg-icon svg-icon-2"></span>
              </button>
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="delete(market.InstrumentID)"
                      title="Delete the market">
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                class="svg-icon svg-icon-2">
              </span>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="pagination">
        <pagination-controls
          previousLabel="Prev"
          nextLabel="Next"
          responsive="true"
          (pageChange)="handlePageChange($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>
