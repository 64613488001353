import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, UserModel} from '../../auth';
import {UserService} from '../../../services/user.service';
import {Payment} from '../../../model/paypal/payment';
import {ProductService} from '../../../services/product.service';
import {Product} from '../../../model/paypal/product';
import {PaymentService} from '../../../services/payment.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  stripePriceId = '';
  userId = '';
  user: UserModel;
  productId = '';
  product: Product;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private userService: UserService,
              private productService: ProductService,
              private paymentService: PaymentService) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.stripePriceId != null
      && this.route.snapshot.queryParams.clientId != null
      && this.route.snapshot.queryParams.productId != null) {
      this.stripePriceId = this.route.snapshot.queryParams.stripePriceId;
      this.userId = this.route.snapshot.queryParams.clientId;
      this.productId = this.route.snapshot.queryParams.productId;

      this.findUser();
      this.findProduct();
    }
  }

  private findUser(): void {
    this.userService.findById(Number(this.userId))
      .toPromise()
      .then((user) => {
        if (user != null) {
          this.user = user;
        }
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  private findProduct(): void {
    this.productService.findById(Number(this.productId))
      .toPromise()
      .then((product) => {
        if (product != null) {
          this.product = product;
          this.savePayment();
        }
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  private savePayment(): void {

    if (this.user && this.product) {
      const user = new UserModel();
      user.email = this.user.email;

      const newPayment = new Payment();
      newPayment.name = this.product.name;
      newPayment.month = this.product.month;
      newPayment.subTotal = this.product.subTotal;
      newPayment.tax = this.product.tax;
      newPayment.paymentSource = 'STRIPE';
      newPayment.total = Number((this.product.subTotal + this.product.tax).toFixed(2));
      newPayment.currency = this.product.currency;
      newPayment.stripePriceId = this.product.stripePriceId;
      newPayment.user = user;

      this.paymentService.save(newPayment)
        .toPromise()
        .then((result) => {
          this.user.premium = true;
          this.authService.currentUserSubject.next(this.user);
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    }
  }
}
