<div class="card mb-5 mb-xl-8">

  <div class="card-header border-0 pt-4">
    <button type="button" class="btn btn-sm btn-success "
            (click)="create()">Create user
    </button>
  </div>

  <div class="card-body py-3">
    <div class="table-responsive">
      <table datatable class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
        <thead>
        <tr class="fw-bolder text-muted">
          <th>Index</th>
          <th (click)="sortBy('id')">Id</th>
          <th (click)="sortBy('fullName')">Full name</th>
          <th (click)="sortBy('email')">Email</th>
          <th (click)="sortBy('createdAt')">CreatedAt</th>
          <th (click)="sortBy('role')">Role</th>
          <th>Premium</th>
          <th scope="col" colspan="2" align="left">Actions</th>
        </tr>
        <tr>
          <td>
            <input type="text" class="form-control ng-untouched ng-pristine ng-valid" placeholder="Index">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Id"
                   [(ngModel)]="searchId"
                   (input)="changeFilterValue('id', searchId)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Full name"
                   [(ngModel)]="searchFullName"
                   (input)="changeFilterValue('fullname', searchFullName)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Email"
                   [(ngModel)]="searchEmail"
                   (input)="changeFilterValue('email', searchEmail)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="CreatedAt"
                   [(ngModel)]="searchCreatedAt"
                   (input)="changeFilterValue('createdAt', searchCreatedAt)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Parser"
                   [(ngModel)]="searchRole"
                   (input)="changeFilterValue('role', searchRole)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="PremiumPopupComponent"
                   [(ngModel)]="searchPremium"
                   (input)="changeFilterValue('premium', searchPremium)">
          </td>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of users  |
                  paginate: { itemsPerPage: pageSize,
                                   currentPage: pagePaging,
                                   totalItems: count } ;
                  let i = index">
          <td>{{(pageSize * (pagePaging - 1)) + i + 1}}</td>
          <td>{{user.id}}</td>
          <td>{{user.fullname}}</td>
          <td>{{user.email}}</td>
          <td>{{user.createdAt | date:'yy-MM-dd HH:mm'}}</td>
          <td>{{user.role}}</td>
          <td>
            <label class="checkbox checkbox-lg checkbox-inline">
              <input type="checkbox"
                     class="form-check-input"
                     [checked]="user.premium"
                     disabled>
              <span></span>
            </label>
          </td>
          <td>
            <div class="d-flex justify-content-end flex-shrink-0">
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="edit(user.id)"
                      title="Edit the user">
              <span
                [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                class="svg-icon svg-icon-2"></span>
              </button>
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="delete(user.id)"
                      title="Delete the user">
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                class="svg-icon svg-icon-2">
              </span>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="pagination">
        <pagination-controls
          previousLabel="Prev"
          nextLabel="Next"
          responsive="true"
          (pageChange)="handlePageChange($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>

<div [hidden]="!alert" class="alert alert-primary" role="alert">
  {{alertMessage}}
</div>
