export class PageQuoteRequest {
  page: number;
  size: number;

  ascDirection: number;
  criteria: string;

  positionID: any;
  marketId: any;
  marketName: any;
  marketDisplayName: any;
  openDateTime: any;
  openRate: any;
  buySell: any;
  takeProfitRate: any;
  stopLossRate: any;
  amount: any;
  currentRate: any;
  netProfit: any;
  leverage: any;


  constructor(page: number, size: number, ascDirection: number) {
    this.page = page;
    this.size = size;
    this.ascDirection = ascDirection;
  }
}
