import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ResponseModel} from '../model/response-model';

const API_USERS_URL = `${environment.apiUrl}/news`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) {
  }

  findNews(marketSymbol: string): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(`${API_USERS_URL}/findNews/` + marketSymbol, httpOptions);
  }
}
