import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Trader} from '../model/trader';
import {Observable} from 'rxjs';
import {ResponseModel} from '../model/response-model';
import {environment} from 'src/environments/environment';
import {PageTraderRequest} from '../model/paging/pageTraderRequest';
import {PageResponse} from '../model/paging/pageResponse';

const API_USERS_URL = `${environment.apiUrl}/trader`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class TraderService {

  constructor(private http: HttpClient) {
  }

  findAll(pageRequest: PageTraderRequest): Observable<PageResponse> {
    return this.http.post<PageResponse>(`${API_USERS_URL}`, pageRequest, httpOptions);
  }

  findById(traderId: number): Observable<Trader> {
    return this.http.get<Trader>(`${API_USERS_URL}/` + traderId, httpOptions);
  }

  saveTrader(trader: Trader): Observable<Trader> {
    return this.http.post<Trader>(`${API_USERS_URL}/save`, trader, httpOptions);
  }

  deleteTrader(id: number): Observable<ResponseModel> {
    return this.http.delete<ResponseModel>(`${API_USERS_URL}/` + id, httpOptions);
  }
}
