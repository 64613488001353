import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {TranslationService} from './modules/i18n';
// language list
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as chLang} from './modules/i18n/vocabs/ch';
import {locale as esLang} from './modules/i18n/vocabs/es';
import {locale as jpLang} from './modules/i18n/vocabs/jp';
import {locale as deLang} from './modules/i18n/vocabs/de';
import {locale as frLang} from './modules/i18n/vocabs/fr';

import {ActivatedRoute, GuardsCheckEnd, NavigationEnd, Router} from '@angular/router';
import {filter} from "rxjs/operators";

declare let gtag: Function;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  constructor(private route:ActivatedRoute,private router:Router,private translationService: TranslationService) {
      // register translations
      this.translationService.loadTranslations(
        enLang,
        chLang,
        esLang,
        jpLang,
        deLang,
        frLang
      );
  }

  ngOnInit() {
    this.setUpAnalytics();
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        // "event" here is now of type "NavigationEnd"
        if (event instanceof GuardsCheckEnd) {
          gtag('config', 'G-Z27GZR774G',
            {
              page_path: event.urlAfterRedirects
            }
          );
        }
      });
  }

}
