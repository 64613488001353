import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {UserModel} from '../../../modules/auth';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalWindowComponent} from '../../dropdown-menu1/modal-window.component';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {PageUserRequest} from '../../../model/paging/pageUserRequest';
import {PageResponse} from '../../../model/paging/pageResponse';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  private ascDirection = 1;

  title: string;
  users: UserModel[];

  page = 0;
  pagePaging = 1;
  count = 0;
  pageSize = 20;

  pageRequest: PageUserRequest;

  searchId: any;
  searchFullName: any;
  searchRole: any;
  searchPremium: any;
  searchEmail: any;
  searchCreatedAt: any;

  alert = false;
  alertMessage: string;

  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private changeDetector: ChangeDetectorRef,
              private modalService: NgbModal) {
    this.title = 'Users';
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit(): void {
    this.pageRequest = new PageUserRequest(this.page, this.pageSize, this.ascDirection);
    this.reloadData();
    if (this.route.snapshot.queryParams['messageData'] != null) {
      this.viewMessage(this.route.snapshot.queryParams['messageData']);
    }
  }

  viewMessage(message: string): void {
    this.alert = true;
    this.alertMessage = message;
    setTimeout(() => {
      this.alert = false;
      this.changeDetector.detectChanges();
    }, 3000);
  }

  reloadData(): void {
    this.userService.findAll(this.pageRequest)
      .subscribe((pageResponse: PageResponse) => {
        this.count = pageResponse.totalElements;
        this.users = pageResponse.content;
        this.changeDetector.detectChanges();
      });
    // .then((pageResponse: PageResponse) => {
    //   this.count = pageResponse.totalElements;
    //   this.users = pageResponse.content;
    //   this.changeDetector.detectChanges();
    // });
  }

  handlePageChange(event: number): void {
    this.pagePaging = event;
    this.pageRequest.page = event - 1;
    this.reloadData();
  }

  sortBy(criteria: string): void {
    this.pageRequest.criteria = criteria;

    if (this.pageRequest.ascDirection === -1) {
      this.pageRequest.ascDirection = 1;
    } else {
      this.pageRequest.ascDirection = -1;
    }
    this.reloadData();
  }

  changeFilterValue(column: string, value: any): void {
    switch (column) {
      case 'id':
        this.pageRequest.searchId = value;
        break;
      case 'fullname':
        this.pageRequest.searchFullName = value;
        break;
      case 'email':
        this.pageRequest.searchEmail = value;
        break;
      case 'createdAt':
        this.pageRequest.searchCreatedAt = value;
        break;
      case 'role':
        this.pageRequest.searchRole = value;
        break;
      case 'premium':
        this.pageRequest.searchPremium = value;
        break;
    }
    this.reloadData();
  }

  create(): void {
    this.router.navigate(['/user'],
      {
        queryParams: {
          userId: 0,
          mode: 'create'
        }
      });
  }

  edit(id: number): void {
    this.router.navigate(['/user'],
      {
        queryParams: {
          userId: id,
          mode: 'edit'
        }
      });
  }

  delete(id: number): void {
    this.makeQuestion('User will remove. Are you agree?');
    this.modalRef.result.then((result) => {
      console.log('false');
    }, (reason) => {
      this.userService.deleteUser(id)
        .toPromise()
        .then((result) => {
          this.reloadData();
          this.viewMessage(result.answer);
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    });
  }

  private makeQuestion(question: string): void {
    this.modalRef = this.modalService.open(ModalWindowComponent);
    this.modalRef.componentInstance.my_modal_title = 'Question';
    this.modalRef.componentInstance.my_modal_content = question;
  }
}
