import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {PageResponse} from '../model/paging/pageResponse';
import {PageQuoteRequest} from '../model/paging/pageQuoteRequest';

const API_USERS_URL = `${environment.apiUrl}/quote`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private http: HttpClient) {
  }

  findByTrader(traderId: string, pageRequest: PageQuoteRequest): Observable<PageResponse> {
    return this.http.post<PageResponse>(`${API_USERS_URL}/bytrader/` + traderId, pageRequest, httpOptions);
  }

  findAllQuotes(pageRequest: PageQuoteRequest): Observable<PageResponse> {
    return this.http.post<PageResponse>(`${API_USERS_URL}`, pageRequest, httpOptions);
  }
}
