import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ResponseModel} from '../model/response-model';
import {Product} from '../model/paypal/product';
import {environment} from 'src/environments/environment';

const API_USERS_URL = `${environment.apiUrl}/product`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) {
  }

  findActiveProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(`${API_USERS_URL}/activeProducts`);
  }

  findAll(): Observable<Product[]> {
    return this.http.get<Product[]>(`${API_USERS_URL}`, httpOptions);
  }

  findById(id: number): Observable<Product> {
    return this.http.get<Product>(`${API_USERS_URL}/` + id, httpOptions);
  }

  save(product: Product): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/save`, product, httpOptions);
  }

  delete(id: number): Observable<ResponseModel> {
    return this.http.delete<ResponseModel>(`${API_USERS_URL}/` + id, httpOptions);
  }
}
