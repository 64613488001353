export class PageTraderRequest {
  page: number;
  size: number;

  ascDirection: number;
  criteria: string;

  searchId: any;
  searchName: any;
  searchParser: any;
  searchRating: any;

  constructor(page: number, size: number, ascDirection: number) {
    this.page = page;
    this.size = size;
    this.ascDirection = ascDirection;
  }
}
