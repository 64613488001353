<div class="card mb-5 mb-xl-8">

  <div class="card-body py-3">
    <div class="table-responsive">
      <table datatable class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
        <thead>
        <tr class="fw-bolder text-muted">
          <th (click)="sortBy('index')" align="left">Index</th>
          <th (click)="sortBy('PositionID')" align="left">Id</th>
          <th (click)="sortBy('market.id')" align="left">MarketId</th>
          <th (click)="sortBy('market.name')">Market</th>
          <th style="width: 18%" (click)="sortBy('market.description')">Description</th>
          <th (click)="sortBy('OpenDateTime')">OpenDateTime</th>
          <th (click)="sortBy('OpenRate')">Open</th>
          <th (click)="sortBy('buySell')">BuySell</th>
          <th (click)="sortBy('TakeProfitRate')">Profit</th>
          <th (click)="sortBy('StopLossRate')">Stop</th>
          <th (click)="sortBy('Amount')">Amount</th>
          <th (click)="sortBy('CurrentRate')">Current</th>
          <th (click)="sortBy('NetProfit')">NetProfit</th>
          <th (click)="sortBy('Leverage')">Leverage</th>
        </tr>
        <tr>
          <td><input type="text" class="form-control ng-untouched ng-pristine ng-valid" placeholder="Index"
          ></td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Id"
                   [(ngModel)]="positionID"
                   (input)="changeFilterValue('PositionID', positionID)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="MarketId"
                   [(ngModel)]="marketId"
                   (input)="changeFilterValue('marketId', marketId)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Market"
                   [(ngModel)]="marketName"
                   (input)="changeFilterValue('marketName', marketName)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="description"
                   [(ngModel)]="marketDisplayName"
                   (input)="changeFilterValue('marketDisplayName', marketDisplayName)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="OpenDateTime"
                   [(ngModel)]="openDateTime"
                   (input)="changeFilterValue('OpenDateTime', openDateTime)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Open"
                   [(ngModel)]="openRate"
                   (input)="changeFilterValue('openRate', openRate)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="BuySell"
                   [(ngModel)]="buySell"
                   (input)="changeFilterValue('buySell', buySell)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="TakeProfit"
                   [(ngModel)]="takeProfitRate"
                   (input)="changeFilterValue('takeProfitRate', takeProfitRate)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="StopLoss"
                   [(ngModel)]="stopLossRate"
                   (input)="changeFilterValue('stopLossRate', stopLossRate)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="StopLoss"
                   [(ngModel)]="amount"
                   (input)="changeFilterValue('amount', amount)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="StopLoss"
                   [(ngModel)]="currentRate"
                   (input)="changeFilterValue('currentRate', currentRate)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="StopLoss"
                   [(ngModel)]="netProfit"
                   (input)="changeFilterValue('netProfit', netProfit)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="StopLoss"
                   [(ngModel)]="leverage"
                   (input)="changeFilterValue('leverage', leverage)">
          </td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let quote of quotes |
                  paginate: { itemsPerPage: pageSize,
                                   currentPage: pagePaging,
                                   totalItems: count } ;
                  let i = index">
          <td>{{(pageSize * (pagePaging - 1)) + i + 1}}</td>
          <td>{{quote.PositionID}}</td>
          <td>{{quote.market.InstrumentID}}</td>
          <td>{{quote.market.SymbolFull}}</td>
          <td>{{quote.market.InstrumentDisplayName}}</td>
          <td>{{quote.OpenDateTime | date:'yy-MM-dd HH:mm'}}</td>
          <td>{{quote.OpenRate}}</td>
          <td>{{quote.buySell}}</td>
          <td>{{quote.TakeProfitRate}}</td>
          <td>{{quote.StopLossRate}}</td>
          <td>{{quote.Amount}}</td>
          <td>{{quote.CurrentRate}}</td>
          <td>{{quote.NetProfit}}</td>
          <td>{{quote.Leverage}}</td>
        </tr>
        </tbody>
      </table>

      <div class="pagination">
        <pagination-controls
          previousLabel="Prev"
          nextLabel="Next"
          responsive="true"
          (pageChange)="handlePageChange($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>
