export class Product {
  id: number;
  active: boolean;
  name: string;
  subTotal: number;
  tax: number;
  month: number;
  currency: string;
  stripePriceId: string;
}
