<div [hidden]="!trialHasEnded" class="alert alert-primary" style="color:red" role="alert">
  {{trialHasEndedMessage}}. After registration, you have 3 days demo access. Please to select any product
</div>

<div id="kt_account_profile_details" class="card card-xl-stretch mb-xl-8 collapse show">
  <form class="form" #payForm="ngForm">
    <div class="card-body border-top p-9">
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Product</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <select (change)="onChangeProduct()" class="form-control" [(ngModel)]="payment.product" id="product"
                      name="formProduct">
                <option *ngFor="let product of products" [ngValue]="product">{{ product.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="row mb-6">-->
<!--        <label class="col-lg-4 col-form-label  fw-bold fs-6">Name</label>-->
<!--        <div class="col-lg-8 fv-row">-->
<!--          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"-->
<!--                 [(ngModel)]='payment.name' placeholder="Name" name="Name" disabled-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mb-6">-->
<!--        <label class="col-lg-4 col-form-label  fw-bold fs-6">Month</label>-->
<!--        <div class="col-lg-8 fv-row">-->
<!--          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"-->
<!--                 [(ngModel)]='payment.month' placeholder="Month" name="Month" disabled-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mb-6">-->
<!--        <label class="col-lg-4 col-form-label fw-bold fs-6">Currency</label>-->
<!--        <div class="col-lg-8 fv-row">-->
<!--          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"-->
<!--                 [(ngModel)]='payment.currency' placeholder="currency" name="currency" disabled-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mb-6">-->
<!--        <label class="col-lg-4 col-form-label fw-bold fs-6">SubTotal</label>-->
<!--        <div class="col-lg-8 fv-row">-->
<!--          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"-->
<!--                 [(ngModel)]='payment.subTotal' placeholder="subTotal" name="subTotal" disabled-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Tax</label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                 [(ngModel)]='payment.tax' placeholder="tax" name="tax" disabled
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Total USD:</label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                 [(ngModel)]='payment.total' placeholder="total USD" name="total" disabled
          />
        </div>
      </div>
    </div>
  </form>

  <div class="card-footer d-flex justify-content-end py-6 px-9">
    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <button (click)="checkout()" class="btn btn-info">
            <img src="./assets/images/stripe-payment.png" alt="Stripe" style="max-width: 10%"/>
            Stripe payment
          </button>
        </div>
        <div class="col-sm-4">
        </div>
        <div class="col-sm-4">
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
      </div>
    </div>
  </div>
</div>
