import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseModel} from '../model/response-model';
import {UserModel} from '../modules/auth';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {PageResponse} from '../model/paging/pageResponse';
import {PageUserRequest} from '../model/paging/pageUserRequest';

const API_USERS_URL = `${environment.apiUrl}/user`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  findAll(pageRequest: PageUserRequest): Observable<PageResponse> {
    return this.http.post<PageResponse>(`${API_USERS_URL}`, pageRequest, httpOptions);
  }

  findById(userId: number): Observable<UserModel> {
    return this.http.get<UserModel>(`${API_USERS_URL}/` + userId);
  }

  findByLogin(login: string): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(`${API_USERS_URL}/` + login);
  }

  changePassword(user: UserModel, pass: string): Observable<ResponseModel> {
    return this.http.put<ResponseModel>(`${API_USERS_URL}/changePassword/` + pass, user, httpOptions);
  }

  saveUser(user: UserModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/save`, user, httpOptions);
  }

  deleteUser(id: number): Observable<ResponseModel> {
    return this.http.delete<ResponseModel>(`${API_USERS_URL}/` + id, httpOptions);
  }

  subscribeForDeals(email: string): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/subscribeForDeals`, email, httpOptions);
  }
}
