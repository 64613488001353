import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lsValue = localStorage.getItem(this.authLocalStorageToken);

    if (lsValue) {
      const authData = JSON.parse(lsValue);
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ` + authData.authToken,
      });

      const paramReq = req.clone({
        headers: httpHeaders
      });
      return next.handle(paramReq);
    } else {
      return next.handle(req);
    }
  }
}
