import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../../../services/payment.service';
import {Payment} from '../../../../model/paypal/payment';
import {Product} from '../../../../model/paypal/product';

@Component({
  selector: 'app-payment-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class PaymentEditComponent implements OnInit {

  payment: Payment;
  paymentId: number;
  mode: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private paymentService: PaymentService) {
    this.payment = new Payment();
    this.payment.product = new Product();
  }

  ngOnInit(): void {
    this.paymentId = this.route.snapshot.queryParams['paymentId'];
    this.mode = this.route.snapshot.queryParams['mode'];

    if (this.paymentId != null && this.mode === 'edit') {
      this.paymentService.findById(this.paymentId)
        .toPromise()
        .then((data) => {
          if (data != null) {
            this.payment = data;
            this.payment.startPeriod = new Date(data.startPeriod);
            this.payment.endPeriod = new Date(data.endPeriod);
            this.changeDetector.detectChanges();
          }
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    } else {
      this.payment = new Payment();
    }
  }

  save(): void {
    this.paymentService.save(this.payment)
      .toPromise()
      .then((result) => {
        this.router.navigate(['/paymentList'],
          {
            queryParams:
              {
                messageData: result.answer
              }
          });
      })
      .catch((error) => {
        this.router.navigate(['/error'],
          {
            queryParams:
              {
                errorCode: error.status
              }
          });
      });
  }

  back(): void {
    this.router.navigate(['/paymentList']);
  }

  openUser(id: number): void {
    this.router.navigate(['/user'],
      {
        queryParams: {
          userId: id,
          mode: 'edit',
          returnUrl: '/payments:' + this.paymentId
        }
      });
  }
}
