<div id="kt_account_profile_details" class="card card-xl-stretch mb-xl-8 collapse show">
  <div
    class="card-header border-0 cursor-pointer"
    role="button"
    data-bs-toggle="collapse"
    data-bs-target="#kt_account_profile_details"
    aria-expanded="true"
    aria-controls="kt_account_profile_details">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">Edit payment</h3>
    </div>
  </div>

  <form class="form" #paymentForm="ngForm">
    <div class="card-body border-top p-9">
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Id</label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                     [ngModel]="payment.id" placeholder="id" name="id" disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">StartPeriod</label>
        <div class="col-lg-8 fv-row">
          <input type="date" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                 [ngModel]="payment.startPeriod | date:'yyyy-MM-dd'" [value]="payment.startPeriod | date:'yyyy-MM-dd'" placeholder="StartPeriod" name="StartPeriod"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">EndPeriod</label>
        <div class="col-lg-8 fv-row">
          <input type="date" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                 [ngModel]="payment.endPeriod | date:'yyyy-MM-dd'" [value]="payment.endPeriod | date:'yyyy-MM-dd'" placeholder="EndPeriod" name="EndPeriod"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">Name</label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solidmb-3 mb-lg-0"
                 [(ngModel)]="payment.name" placeholder="Name" name="Name"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Month</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="payment.month" placeholder="Month" name="Month"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Subtotal</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="payment.subTotal" placeholder="Subtotal" name="Subtotal"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Tax</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="payment.tax" placeholder="Tax" name="Tax"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Total</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="payment.total" placeholder="Total" name="Total"
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Currency</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" class="form-control form-control-lg form-control-solid"
                 [(ngModel)]="payment.currency" placeholder="Currency" name="Currency" disabled
          />
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">UserEmail</span>
        </label>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 fv-row">
              <input type="text" class="form-control form-control-lg form-control-solid"
                     [(ngModel)]="payment.user.email" placeholder="UserName" name="UserName"
              />
            </div>
            <div class="col-lg-6 fv-row">
              <button class="btn btn-success" (click)="openUser(payment.user.id)"
                      title="Open the user by username">Open</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button type="button" class="btn btn-success" (click)="save()">Save</button>
      <button type="button" class="btn btn-primary" (click)="back()">Back</button>
    </div>
  </form>
</div>
