import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg';
import {NgxPaginationModule} from 'ngx-pagination';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TraderListComponent} from './components/trader/list/traderlist.component';
import {TraderEditComponent} from './components/trader/edit/edit.component';
import {MarketlistComponent} from './components/market/marketlist/marketlist.component';
import {QuotelistComponent} from './components/quote/quotelist/quotelist.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {UserComponent} from './components/user/list/user.component';
import {DealComponent} from './components/deal/deal/deal.component';
import {JwtInterceptor} from './insterceptor/jwt.interceptor';
import {UserEditComponent} from './components/user/edit/user-edit.component';
import {AuthService} from './modules/auth/services/auth.service';
import {FakeAPIService} from './_fake/fake-api.service';
import {environment} from 'src/environments/environment';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {NgxPayPalModule} from 'ngx-paypal';
import {PaymentComponent} from './modules/payment/payment/payment.component';
import {ProductEditComponent} from './modules/payment/product/edit/edit.component';
import {ProductListComponent} from './modules/payment/product/list/productlist.component';
import {PaymentListComponent} from './modules/payment/payments/list/paymentlist.component';
import {PaymentEditComponent} from './modules/payment/payments/edit/edit.component';
import {ModalWindowComponent} from './components/dropdown-menu1/modal-window.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PaymentSuccessComponent} from './modules/payment/payment-success/payment-success.component';
import {PaymentErrorComponent} from './modules/payment/payment-error/payment-error.component';
import {MarketEditComponent} from './components/market/market-edit/market-edit.component';
import {DealsConsolidateComponent} from './components/deals-consolidate/deals-consolidate.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';

function appInitializer(authService: AuthService): any {
  return () => {
    return new Promise((resolve) => {
      console.log('getUserByToken from appModule');
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    TraderListComponent,
    TraderEditComponent,
    MarketlistComponent,
    QuotelistComponent,
    UserComponent,
    DealComponent,
    UserEditComponent,
    ProductListComponent,
    ProductEditComponent,
    PaymentListComponent,
    PaymentEditComponent,
    PaymentComponent,
    ModalWindowComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    MarketEditComponent,
    DealsConsolidateComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgbModule,
    NgxPayPalModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    NgxPaginationModule,
    Ng2SmartTableModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
