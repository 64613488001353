<div class="card mb-5 mb-xl-8">

  <div class="card-header border-0 pt-4">
    <button type="button" class="btn btn-sm btn-success "
            (click)="create()">Create product
    </button>
  </div>

  <div class="card-body py-3">
    <div class="table-responsive">
      <table datatable class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
        <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px" (click)="sortBy('index')" align="left">Index</th>
          <th class="min-w-80px" (click)="sortBy('id')" align="left">Id</th>
          <th class="min-w-80px" (click)="sortBy('active')" align="left">Active</th>
          <th class="min-w-50px" (click)="sortBy('name')">Name</th>
          <th class="min-w-50px" (click)="sortBy('subtotal')">Subtotal</th>
          <th class="min-w-50px" (click)="sortBy('tax')">Tax</th>
          <th class="min-w-100px text-end" scope="col" colspan="2">Actions</th>
        </tr>
        <tr>
          <td>
            <input type="text" class="form-control ng-untouched ng-pristine ng-valid" placeholder="Index">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Id"
                   [(ngModel)]="searchId"
                   (input)="changeFilterValue('id', searchId)">
          </td>
          <td>
            <label class="checkbox checkbox-lg checkbox-inline">
              <input type="checkbox" class="form-check-input"
                     [(ngModel)]="searchActive"
                     (input)="changeFilterValue('active', searchActive)">
            </label>
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Name"
                   [(ngModel)]="searchName"
                   (input)="changeFilterValue('name', searchName)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="SubTotal"
                   [(ngModel)]="searchSubTotal"
                   (input)="changeFilterValue('subTotal', searchSubTotal)">
          </td>
          <td>
            <input type="text"
                   class="form-control ng-untouched ng-pristine ng-valid"
                   placeholder="Tax"
                   [(ngModel)]="searchTax"
                   (input)="changeFilterValue('tax', searchTax)">
          </td>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let product of products  |
                  paginate: { itemsPerPage: pageSize,
                                   currentPage: page,
                                   totalItems: count } ;
                  let i = index">
          <td>{{(pageSize * (page - 1)) + i + 1}}</td>
          <td>{{product.id}}</td>
          <td>
            <label class="checkbox checkbox-lg checkbox-inline">
              <input type="checkbox"
                     class="form-check-input"
                     [checked]="product.active"
                     disabled>
              <span></span>
            </label>
          </td>
          <td>{{product.name}}</td>
          <td>{{product.subTotal}}</td>
          <td>{{product.tax}}</td>
          <td class="tableButton">
            <div class="d-flex justify-content-end flex-shrink-0">
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="edit(product.id)"
                      title="Edit the product">
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                  class="svg-icon svg-icon-2">
                </span>
              </button>
              <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                      (click)="delete(product.id)"
                      title="Delete the product">
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                  class="svg-icon svg-icon-2">
                </span>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="pagination">
        <pagination-controls
          previousLabel="Prev"
          nextLabel="Next"
          responsive="true"
          (pageChange)="handlePageChange($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>

<div [hidden]="!alert" class="alert alert-primary" role="alert">
  {{alertMessage}}
</div>
