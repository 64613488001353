import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseModel} from '../model/response-model';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

const API_USERS_URL = `${environment.apiUrl}/deal`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class DealService {

  constructor(private http: HttpClient) {
  }

  findAll(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(`${API_USERS_URL}/group`);
  }

  findByUserIdAndDayFilter(email: string, dateFrom: string, dateTo: string): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(`${API_USERS_URL}/group/` + email + '/' + dateFrom + '/' + dateTo);
  }

  doSubscribe(dealId: number, email: string): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/subscribe/` + email, dealId, httpOptions);
  }


  doUnsubscribe(dealId: number, email: string): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/unsubscribe/` + email, dealId, httpOptions);
  }

  createAll(): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${API_USERS_URL}/createall`, '', httpOptions);
  }
}
