import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../../../services/payment.service';
import {Payment} from '../../../../model/paypal/payment';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalWindowComponent} from '../../../../components/dropdown-menu1/modal-window.component';

@Component({
  selector: 'app-payment',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class PaymentListComponent implements OnInit {

  private ascDirection = 1;

  title: string;
  payments: Payment[];

  page = 1;
  count = 0;
  pageSize = 20;

  searchId: any;
  searchName: any;
  searchStartPeriod: any;
  searchEndPeriod: any;
  searchSubTotal: any;
  searchTax: any;
  searchUser: any;

  criteria: string;

  columnFilter: string;
  valueFilter: string;

  alert: boolean = false;
  alertMessage: string;

  modalRef: NgbModalRef;
  modalOptions: NgbModalOptions;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private paymentService: PaymentService,
              private changeDetector: ChangeDetectorRef,
              private modalService: NgbModal) {
    this.title = 'Payment';
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit(): void {
    this.reloadData();
    if (this.route.snapshot.queryParams.messageData != null) {
      this.viewMessage(this.route.snapshot.queryParams.messageData);
    }
  }

  viewMessage(message: string): void {
    this.alert = true;
    this.alertMessage = message;
    setTimeout(() => {
      this.alert = false;
      this.changeDetector.detectChanges();
    }, 3000);
  }

  reloadData(): void {
    this.paymentService.findAll()
      .toPromise()
      .then((payments: Payment[]) => {
        payments = this.sorting(payments);
        payments = this.filter(payments);

        this.payments = payments;
        this.changeDetector.detectChanges();
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
  }

  create(): void {
    this.router.navigate(['/paymentEdit'],
      {
        queryParams: {
          paymentId: 0,
          mode: 'create'
        }
      });
  }

  edit(id: number): void {
    this.router.navigate(['/paymentEdit'],
      {
        queryParams: {
          paymentId: id,
          mode: 'edit'
        }
      });
  }

  delete(id: number): void {
    this.makeQuestion('Payment will remove. Are you agree?');
    this.modalRef.result.then((result) => {
      console.log('false');
    }, (reason) => {
      this.paymentService.delete(id)
        .toPromise()
        .then((result) => {
          this.reloadData();
          this.viewMessage(result.answer);
        })
        .catch((error) => {
          this.router.navigate(['/error'],
            {
              queryParams:
                {
                  errorCode: error.status
                }
            });
        });
    });
  }

  private makeQuestion(question: string): void {
    this.modalRef = this.modalService.open(ModalWindowComponent);
    this.modalRef.componentInstance.my_modal_title = 'Question';
    this.modalRef.componentInstance.my_modal_content = question;
  }

  sortBy(criteria: string): void {
    this.criteria = criteria;
    this.reloadData();
  }

  changeFilterValue(column: string, value: any): void {
    this.columnFilter = column;
    this.valueFilter = value;
    this.reloadData();
  }

  private sorting(payments: Payment[]): Payment[] {
    if (this.ascDirection === -1) {
      switch (this.criteria) {
        case 'id':
          payments.sort((a, b) => a.id > b.id ? 1 : -1);
          break;
        case 'name':
          payments.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 'subTotal':
          payments.sort((a, b) => a.subTotal > b.subTotal ? 1 : -1);
          break;
        case 'tax':
          payments.sort((a, b) => a.tax > b.tax ? 1 : -1);
          break;
        default:
          break;
      }
      this.ascDirection = 1;
    } else {
      switch (this.criteria) {
        case 'id':
          payments.sort((a, b) => b.id > a.id ? 1 : -1);
          break;
        case 'name':
          payments.sort((a, b) => b.name.localeCompare(a.name));
          break;
        case 'subTotal':
          payments.sort((a, b) => b.subTotal > a.subTotal ? 1 : -1);
          break;
        case 'tax':
          payments.sort((a, b) => b.tax > a.tax ? 1 : -1);
          break;
        default:
          break;
      }
      this.ascDirection = -1;
    }
    return payments;
  }

  private filter(payments: Payment[]): Payment[] {
    if (this.columnFilter !== undefined && this.valueFilter !== undefined) {
      payments = payments.filter((payment) => {
        switch (this.columnFilter) {
          case 'id':
            return String(payment.id).includes(this.valueFilter);
            break;
          case 'name':
            return String(payment.name.toLowerCase()).includes(this.valueFilter.toLowerCase());
            break;
          case 'subTotal':
            return String(payment.subTotal).includes(this.valueFilter);
            break;
          case 'tax':
            return String(payment.tax).includes(this.valueFilter);
            break;
          default:
            break;
        }
      });
    }
    return payments;
  }
}
